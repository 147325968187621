import {
	Backdrop,
	Badge,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ChatIcon from "@mui/icons-material/Chat";
import Chat from "./Chat";
import { Box } from "@mui/system";
import ViewOthersProfile from "./ViewOthersProfile";
import FaceIcon from "@mui/icons-material/Face";
import io from "socket.io-client";
import RoleChnage from "./RoleChnage";
import { DataGrid } from "@mui/x-data-grid";
import Search from "../../../../Pagination/Search";
import Pagination from "../../../../Pagination/Pagination";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import EditUser from "./EditUser";

const socket = io(`${process.env.REACT_APP_API_PATH}`);

const AllUsers = () => {
	const [deleted, setDeleted] = useState(false);
	const [room, setRoom] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [datas, setDatas] = useState();
	const [openProfile, setOpenProfile] = useState();
	const [openChat, setOpenChat] = useState(false);
	const [lastLogged, setLastLogged] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/lastVisited`)
			.then((res) => res.json())
			.then((data) => {
				setLastLogged(data);
			});
	}, [submitting]);

	const [users, setUsers] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_PATH}/allusers?page=${currentPage}&perPage=${itemsPerPage}${searchQuery}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data.data);
				setTotalItems(data?.pagination?.total);
			});
	}, [deleted, submitting, openChat, currentPage, itemsPerPage, searchQuery]);

	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/deleteUser/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That user has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const handleStatus = (id, status) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: `Yes, ${status}  it!`,
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(`${process.env.REACT_APP_API_PATH}/updateStatus/${id}`, {
						status: status,
					})
					.then(function (response) {
						setDeleted(false);
						Swal.fire({
							icon: "success",
							title: "Successfully Updated",
							showConfirmButton: false,
							timer: 1500,
						});
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const [user, setUser] = useState();
	const [chats, setChats] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/chats`)
			.then((res) => res.json())
			.then((data) => {
				setChats(data);
			});
	}, [openChat, user]);

	const [openEdit, setOpenEdit] = useState(false);

	const columns = [
		{
			field: "title",
			headerName: "Name",
			minWidth: 200,
			valueGetter: (params) => params?.row?.headline?.title,
		},
		{
			field: "url",
			headerName: "Username",
			minWidth: 100,
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 220,
		},
		{
			field: "phone",
			headerName: "Phone",
			minWidth: 120,
			valueGetter: (params) => params?.row?.phone,
			renderCell: (params) => {
				return (
					<a
						href={`tel:${params?.row?.phone}`}
						className='textColor'
						style={{ textDecoration: "none" }}>
						{params?.row?.phone}
					</a>
				);
			},
		},
		{
			field: "Role",
			headerName: "Role",
			minWidth: 150,
			filterable: false,
			renderCell: (params) => {
				return <RoleChnage user={params?.row} setSubmitting={setSubmitting} />;
			},
		},

		{
			field: "lastVisitedTime",
			headerName: "Last Logged In",
			minWidth: 220,
			filterable: false,
			renderCell: (params) => {
				return lastLogged?.find((u) => u?.userName === params?.row?.url)
					?.lastVisited;
			},
		},
		{
			field: "joiningTime",
			headerName: "Joining Time",
			minWidth: 220,
		},
		{
			field: "recuretedBy",
			headerName: "Recureted By",
			minWidth: 120,
		},

		{
			field: "RecruitCount",
			headerName: "Recruit Count",
			minWidth: 100,
			filterable: false,
			renderCell: (params) => {
				return (
					users?.filter((item) => item.recuretedBy === params?.row?.url)
						?.length || 0
				);
			},
		},

		{
			field: "Action",
			headerName: "Action",
			minWidth: 470,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }} className='online'>
						<Button
							onClick={() => {
								setDatas(params?.row);
								setOpenEdit(true);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<SupervisedUserCircleIcon />
						</Button>
						<Button
							onClick={() => {
								setDatas(params?.row);
								setOpenProfile(true);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<FaceIcon />
						</Button>
						<Badge
							color={
								chats &&
								chats?.find((u) => u?.chatRoom === params?.row?.url)?.status ===
									"Online"
									? "success"
									: "error"
							}
							badgeContent={
								chats
									?.find((u) => u?.chatRoom === params?.row?.url)
									?.chats?.filter((d) => d?.adminSeen === false)?.length
							}>
							<Button
								onClick={() => {
									setOpenChat(true);
									setUser(params?.row);
									setRoom(params?.row?.url);
									socket.emit("join_room", params?.row?.url);
								}}
								className=' border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<ChatIcon />
							</Button>
						</Badge>

						{params?.row?.status === "Active" && (
							<Button
								onClick={() =>
									window.open(`/@${params?.row?.url}`, "_blank").focus()
								}
								className='button border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<LanguageIcon />
							</Button>
						)}
						{params?.row?.role === "Admin" ? (
							<Button
								disabled
								className=' border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "gray !important",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<DeleteIcon />
							</Button>
						) : (
							<Button
								className='button border'
								onClick={() => handleDelete(params?.row?._id)}
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<DeleteIcon />
							</Button>
						)}
						{params?.row?.role === "Admin" ? (
							<Button
								disabled
								className=' border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "gray !important",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<CloseIcon />
							</Button>
						) : (
							<>
								{params?.row?.status === "Inactive" ? (
									<Button
										className='button border'
										onClick={() => handleStatus(params?.row?._id, "Active")}
										sx={{
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "green !important",
											borderRadius: "25px",
											m: 0.5,
										}}
										variant='contained'>
										<DoneIcon />
									</Button>
								) : (
									<Button
										className='button border'
										onClick={() => handleStatus(params?.row?._id, "Inactive")}
										sx={{
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "#ef1a1a !important",
											borderRadius: "25px",
											m: 0.5,
										}}
										variant='contained'>
										<CloseIcon />
									</Button>
								)}
							</>
						)}
					</Box>
				);
			},
		},
	];
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				All Users
			</Typography>

			{users && (
				<Box sx={{ width: "100%", height: "85vh" }}>
					<DataGrid
						rows={users}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: () => (
								<Search
									setSearchQuery={setSearchQuery}
									searchFields={[
										"email",
										"headline.title",
										"joiningTime",
										"phone",
										"recuretedBy",
										"role",
										"status",
										"url",
									]}
								/>
							),
							Footer: () => (
								<Pagination
									totalItems={totalItems}
									currentPage={currentPage}
									itemsPerPage={itemsPerPage}
									onPageChange={handlePageChange}
								/>
							),
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users}>
				<CircularProgress color='inherit' />
			</Backdrop>

			{openProfile && (
				<ViewOthersProfile
					open={openProfile}
					setOpen={setOpenProfile}
					user={datas}
				/>
			)}
			{openEdit && (
				<EditUser open={openEdit} setOpen={setOpenEdit} anotherUser={datas} />
			)}

			{openChat && (
				<Chat
					openChat={openChat}
					setOpenChat={setOpenChat}
					user={user}
					socket={socket}
					room={room}
				/>
			)}
		</Box>
	);
};

export default AllUsers;
