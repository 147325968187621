import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Backdrop, Box, Button, CircularProgress, Grid } from "@mui/material";
import SIngleView from "./SIngleView";

const ViewNews = () => {
	const [news, setNews] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/news`)
			.then((res) => res.json())
			.then((data) => {
				setNews(data);
			});
	}, []);
	const [open, setOpen] = React.useState(false);
	const [view, setView] = useState();
	return (
		<Box sx={{ px: 2 }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				News
			</Typography>
			<Grid container spacing={2} sx={{ mx: "auto" }}>
				{news?.map((n) => (
					<Grid item md={4} sm={6} xs={12}>
						<Card
							style={{ backgroundImage: `url(/bg-img.jpg)` }}
							elevation={3}
							sx={{
								maxWidth: 345,
								borderRadius: 3,
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
							}}>
							<Box>
								<CardHeader
									className='textColor'
									sx={{ textAlign: "center", fontWeight: "bold", py: 1 }}
									title={n?.title}
									subheader={n?.submitTime}
								/>
								<CardMedia
									component='img'
									height='194'
									sx={{ px: 1 }}
									image={n?.image1}
								/>
								<Typography
									variant='body2'
									sx={{ pt: 2, px: 1, whiteSpace: "pre-line" }}>
									{n?.news.slice(0, 150)}......
								</Typography>
							</Box>
							<CardContent sx={{ p: 0 }}>
								<Button
									onClick={() => {
										setOpen(true);
										setView(n);
									}}
									className='bgColor'
									variant='contained'
									sx={{
										mt: 1.5,
										px: 3,
										fontWeight: "bold",
										border: "2px solid",
										borderRadius: "25px",
									}}>
									View More
								</Button>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
			{open && <SIngleView open={open} setOpen={setOpen} view={view} />}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!news}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default ViewNews;
