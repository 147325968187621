import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
	Backdrop,
	Box,
	Card,
	CircularProgress,
	Grid,
	Snackbar,
	Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import Details from "./Details";

const ViewSocialMediaPost = () => {
	const [training, setTraining] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/posts`)
			.then((res) => res.json())
			.then((data) => {
				setTraining(data);
			});
	}, []);
	const [alert, setAlert] = useState(false);
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setAlert(false);
	};
	return (
		<Box sx={{ px: 2 }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'
				gutterBottom>
				Social media posts
			</Typography>
			<Grid container spacing={2} sx={{ justifyContent: "center" }}>
				{training?.map((n) => (
					<Grid item md={4} xs={12}>
						<Card
							style={{ backgroundImage: `url(/bg-img.jpg)` }}
							elevation={3}
							sx={{
								borderRadius: 3,
								p: 1.5,
							}}>
							<Grid container spacing={2}>
								{n?.video !== "off" && (
									<Grid item xs sx={{ mx: "auto" }}>
										<Box sx={{ position: "relative" }}>
											{" "}
											{n?.video?.trim() !== "" && (
												<ReactPlayer
													key={n?.video}
													className='borderColor react-player'
													style={{ borderRadius: 11, backgroundColor: "black" }}
													width='100%'
													height='100%'
													url={n?.video}
													controls
												/>
											)}
											<Box
												className='bgColor'
												sx={{
													p: 0.3,
													cursor: "pointer",
													position: "absolute",
													bottom: 10,
													right: 10,
													color: "white",
													borderRadius: 1,
												}}>
												<ContentCopyIcon
													onClick={() => {
														navigator.clipboard.writeText(n?.video);
														setAlert(true);
													}}
												/>
											</Box>
										</Box>
									</Grid>
								)}
								{n?.image && (
									<Grid item xs sx={{ mx: "auto" }}>
										<Box sx={{ position: "relative" }}>
											<img src={n?.image} alt='' style={{ width: "100%" }} />
											<Box
												className='bgColor'
												sx={{
													cursor: "pointer",
													position: "absolute",
													bottom: 10,
													right: 10,
													color: "white",
													borderRadius: 1,
												}}>
												<DownloadIcon
													onClick={() => {
														saveAs(n?.image, "image.jpg");
													}}
												/>
											</Box>
										</Box>
									</Grid>
								)}
							</Grid>

							<Typography
								className='textColor'
								sx={{ fontWeight: "bold", py: 0.5 }}
								variant='h6'>
								{n?.title}{" "}
								<ContentCopyIcon
									sx={{ cursor: "pointer" }}
									onClick={() => {
										navigator.clipboard.writeText(n?.title);
										setAlert(true);
									}}
								/>
							</Typography>

							<Typography
								className='textColor'
								variant='body2'
								sx={{ whiteSpace: "pre-line" }}>
								<Details n={n} />
								<ContentCopyIcon
									sx={{ cursor: "pointer" }}
									onClick={() => {
										navigator.clipboard.writeText(n?.description);
										setAlert(true);
									}}
								/>
							</Typography>
						</Card>
					</Grid>
				))}
			</Grid>
			<Snackbar
				open={alert}
				autoHideDuration={3000}
				message='Copied to clipboard!'
				onClose={handleClose}
			/>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!training}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default ViewSocialMediaPost;
