import axios from "axios";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Swal from "sweetalert2";
import { useAPI } from "../../../apiContext";
import { Box } from "@mui/system";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = () => {
    const { databyEmail } = useAPI();
    const [bookLink, setBookLink] = useState("");
    const [submitting, setSubmitting] = useState(false);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_PATH}/Book`).then((res) => {
            setBookLink(res.data?.Book);
        });
    }, [submitting]);
    const onSubmit = () => {
        setSubmitting(true);
        const data = {
            Book: bookLink,
            type: "Book",
            submitTime: new Date().toLocaleString("en-US", {
                timeZone: "America/Los_Angeles",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                timeZoneName: "short",
            }),
        };
        axios
            .put(`${process.env.REACT_APP_API_PATH}/Book`, data)
            .then(function (response) {
                setSubmitting(false);
                Swal.fire({
                    icon: "success",
                    title: "Book updated Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
            {databyEmail?.role === "Admin" && (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "11px",
                    }}>
                    <input
                        type='text'
                        value={bookLink}
                        onChange={(e) => setBookLink(e.target.value)}
                    />
                    <button onClick={() => onSubmit()}>Update</button>
                </Box>
            )}
            <div style={{ margin: "11px" }}>
                <button onClick={handlePrevPage} disabled={pageNumber === 1}>
                    Previous Page
                </button>
                <div style={{ display: "inline-block", margin: "0 10px" }}>
                    Page {pageNumber} of {numPages}
                </div>
                <button onClick={handleNextPage} disabled={pageNumber === numPages}>
                    Next Page
                </button>
                <Document
                    file={bookLink}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={{ httpHeaders: { withCredentials: true } }}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <Page pageNumber={pageNumber} />
                    </div>
                </Document>
            </div>
        </div>
    );
};

export default PdfViewer;
