import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import {
	useSignInWithGoogle,
	useCreateUserWithEmailAndPassword,
	useSendEmailVerification,
} from "react-firebase-hooks/auth";
import auth from "../../Firebase/firebase.config";
import Swal from "sweetalert2";
import GoogleIcon from "@mui/icons-material/Google";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";
import { useAPI } from "../../apiContext";

export default function SignUp({ setSignup, setLoggedUser }) {
	const navigate = useNavigate();
	const { data } = useAPI();
	const [open, setOpen] = React.useState(false);
	const [signInWithGoogle, user2, loading2] = useSignInWithGoogle(auth);
	const [createUserWithEmailAndPassword, user, loading, error] =
		useCreateUserWithEmailAndPassword(auth);
	const [sendEmailVerification, sending] = useSendEmailVerification(auth);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [checked, setChecked] = React.useState(false);
	React.useEffect(() => {
		if (error) {
			Swal.fire({
				icon: "error",
				title: `${error?.message}`,
				showConfirmButton: true,
			});
		}
		if (user || user2) {
			Swal.fire({
				icon: "success",
				title: `Welcome ${(user || user2)?.user?.email}`,
				showConfirmButton: true,
			});
			sendEmailVerification();
			setLoggedUser(user || user2);
			setSignup(false);
			window.location.replace("/jointeam");
		}
	}, [
		data,
		error,
		navigate,
		sendEmailVerification,
		setLoggedUser,
		setSignup,
		user,
		user2,
	]);

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				minHeight='100vh'>
				<Box style={{ marginTop: 3, textAlign: "center" }}>
					<Box>
						<Avatar sx={{ mx: "auto", my: 1 }} className='bgColor'>
							<LockOutlinedIcon />
						</Avatar>
						<Typography
							component='h1'
							variant='h5'
							className='textColor'
							sx={{ fontWeight: "bold" }}>
							Sign Up
						</Typography>
					</Box>
					<TextField
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email Address'
						name='email'
						autoComplete='email'
						autoFocus
						onBlur={(e) => setEmail(e.target.value)}
					/>{" "}
					<Typography
						variant='body2'
						className='textColor'
						sx={{ textDecoration: "underline", cursor: "pointer" }}
						onClick={() => setSignup(false)}>
						Already Have An Account?
					</Typography>
					<TextField
						margin='normal'
						required
						fullWidth
						name='password'
						label='Password'
						type='password'
						id='password'
						onBlur={(e) => setPassword(e.target.value)}
					/>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<input
							type='checkbox'
							onChange={(e) => setChecked(e.target.checked)}
						/>
						<Typography variant='body2' className='textColor'>
							I agree to the{" "}
							<span
								onClick={() => setOpen(true)}
								style={{ textDecoration: "underline", cursor: "pointer" }}>
								Terms of Service
							</span>
						</Typography>
					</Box>
					{checked ? (
						<Button
							className='confirm'
							onClick={() => {
								createUserWithEmailAndPassword(email, password);
							}}
							fullWidth
							variant='contained'
							sx={{
								mt: 1,
							}}>
							Sign Up
						</Button>
					) : (
						<Button
							className='confirm'
							disabled
							fullWidth
							variant='contained'
							sx={{
								mt: 1,
								backgroundColor: "gray !important",
							}}>
							Sign Up
						</Button>
					)}
					-------- or --------
					{checked ? (
						<Button
							fullWidth
							className='confirm'
							onClick={() => signInWithGoogle()}
							sx={{ fontWeight: "bold" }}>
							<GoogleIcon sx={{ mr: 1 }} /> SignUp With Google
						</Button>
					) : (
						<Button
							fullWidth
							disabled
							className='confirm'
							sx={{ fontWeight: "bold", backgroundColor: "gray !important" }}>
							<GoogleIcon sx={{ mr: 1 }} /> SignUp With Google
						</Button>
					)}
				</Box>
			</Box>

			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading || loading2 || sending}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && <TermsAndCondition open={open} setOpen={setOpen} data={data} />}
		</Container>
	);
}
