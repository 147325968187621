import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ShareManage from "./ShareManage";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const SMSBlast = () => {
	const { handleSubmit, reset } = useForm();

	const [selectedUsers, setSelectedUsers] = useState([]);

	const [selectAll, setSelectAll] = useState(false);
	const handleSelectAll = () => {
		if (selectAll) {
			setSelectedUsers([]);
		} else {
			setSelectedUsers(allUsers);
		}
		setSelectAll(!selectAll);
	};

	const [allUsers, setAllUsers] = useState();

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/allusersMiniDetails`)
			.then((res) => res.json())
			.then((data) => {
				setAllUsers(data);
			});
	}, []);

	const [shareText, setShareText] = useState("");

	const [submitting, setSubmitting] = useState(false);
	const onSubmit = () => {
		if (selectedUsers.length === 0) {
			Swal.fire({
				icon: "error",
				title: "Please Select Atleast One User",
				showConfirmButton: false,
				timer: 1500,
			});
			return;
		}

		const data = {
			sms: shareText,
			users: selectedUsers,
			time: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		setSubmitting(true);
		axios
			.post(`${process.env.REACT_APP_API_PATH}/sendSms`, data)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Your Messsage Sent Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
				reset();
				setSelectedUsers([]);
				setSubmitting(false);
			})
			.catch(function (error) {
				console.log("error", error);
				console.log(error);
			});
	};

	return (
		<Box sx={{ px: 2, mt: 2, minHeight: "100vh" }}>
			<Typography
				className='textColor'
				sx={{ mb: 1, fontWeight: "bold" }}
				variant='h4'
				component='div'
				gutterBottom>
				Send SMS Blast
			</Typography>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<Autocomplete
							fullWidth
							multiple
							id='checkboxes-tags-demo'
							options={allUsers || []}
							disableCloseOnSelect
							getOptionLabel={(option) => option.name}
							value={selectedUsers}
							onChange={(event, value) => {
								setSelectedUsers(value);
							}}
							renderOption={(props, option, { selected }) => (
								<li {...props}>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.name}-{option.phone}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									fullWidth
									{...params}
									label='Select Users'
									placeholder='Select Users'
								/>
							)}
						/>
						<button
							onClick={handleSelectAll}
							className='bgColor borderColor'
							style={{
								color: "white",
								cursor: "pointer",
							}}>
							{selectAll ? "Deselect All" : "Select All"}
						</button>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<ShareManage shareText={shareText} setShareText={setShareText} />
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<Button
							className='sendButton'
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Send SMS Blast
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default SMSBlast;
