import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Legend } from "recharts";

const Charts = () => {
	const [users, setUsers] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/allusersurl`)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data);
			})
			.catch((err) => console.log(err));
	}, []);

	const [lastLogged, setLastLogged] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/lastVisited`)
			.then((res) => res.json())
			.then((data) => {
				setLastLogged(data);
			});
	}, []);

	const [data, setData] = useState();
	useEffect(() => {
		const currentDate = new Date().toLocaleDateString();
		setData([
			{
				name: "Not Active Today",
				value:
					users?.length -
					lastLogged?.filter(
						(f) =>
							new Date(f?.lastVisited).toLocaleDateString() === currentDate,
					)?.length,
			},
			{
				name: "Was Active Today",
				value: lastLogged?.filter(
					(f) => new Date(f?.lastVisited).toLocaleDateString() === currentDate,
				)?.length,
			},
		]);
	}, [users, lastLogged]);

	/* const [invitedUsers, setInvitedUsers] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/invitatedusersStatus`)
			.then((res) => res.json())
			.then((data) => {
				setInvitedUsers(data);
			});
	}, []); */
	/* 	const [invitedUsersData, setInvitedUsersData] = useState();
	useEffect(() => {
		setInvitedUsersData([
			{
				name: "Pending",
				value: invitedUsers?.filter((p) => p?.status === "Pending")?.length,
			},
			{
				name: "Pre-site-survey",
				value: invitedUsers?.filter((p) => p?.status === "Pre-site-survey")
					?.length,
			},
			{
				name: "Closed",
				value: invitedUsers?.filter((p) => p?.status === "Closed")?.length,
			},
			{
				name: "Post-site-survey",
				value: invitedUsers?.filter((p) => p?.status === "Post-site-survey")
					?.length,
			},
			{
				name: "Design-started",
				value: invitedUsers?.filter((p) => p?.status === "Design-started")
					?.length,
			},
			{
				name: "Phone-Room-Follow-Up",
				value: invitedUsers?.filter((p) => p?.status === "Phone-Room-Follow-Up")
					?.length,
			},
			{
				name: "Manager-Follow-Up",
				value: invitedUsers?.filter((p) => p?.status === "Manager-Follow-Up")
					?.length,
			},
			{
				name: "Canceled",
				value: invitedUsers?.filter((p) => p?.status === "Canceled")?.length,
			},
			{
				name: "Agent-Follow-Up",
				value: invitedUsers?.filter((p) => p?.status === "Agent-Follow-Up")
					?.length,
			},
			{
				name: "DNQ",
				value: invitedUsers?.filter((p) => p?.status === "DNQ")?.length,
			},
			{
				name: "Alarm-Deal",
				value: invitedUsers?.filter((p) => p?.status === "Alarm-Deal")?.length,
			},
			{
				name: "In-Permitting",
				value: invitedUsers?.filter((p) => p?.status === "In-Permitting")
					?.length,
			},
			{
				name: "Install-Scheduled",
				value: invitedUsers?.filter((p) => p?.status === "Install-Scheduled")
					?.length,
			},
			{
				name: "M2-Funded",
				value: invitedUsers?.filter((p) => p?.status === "M2-Funded")?.length,
			},
		]);
	}, [invitedUsers]); */

	const [activeIndexLastLogged, setActiveIndexLastLogged] = useState(0);
	/* 	const [activeIndexInvitedUsers, setActiveIndexInvitedUsers] = useState(0); */

	const renderActiveShape = (props) => {
		const RADIAN = Math.PI / 180;
		const {
			cx,
			cy,
			midAngle,
			innerRadius,
			outerRadius,
			startAngle,
			endAngle,
			fill,
			payload,
			percent,
			value,
			name,
		} = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? "start" : "end";

		return (
			<g>
				<text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
					{payload.name}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					startAngle={startAngle}
					endAngle={endAngle}
					fill='#0088FE'
				/>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius + 6}
					outerRadius={outerRadius + 10}
					fill='#00C49F'
				/>
				<path
					d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
					stroke={fill}
					fill='none'
				/>
				<circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
				<text
					x={ex + (cos >= 0 ? 1 : -1) * 12}
					y={ey}
					textAnchor={textAnchor}
					fill='#333'>{`${name} ${value}`}</text>
				<text
					x={ex + (cos >= 0 ? 1 : -1) * 12}
					y={ey}
					dy={18}
					textAnchor={textAnchor}
					fill='#999'>
					{`(Rate ${(percent * 100).toFixed(2)}%)`}
				</text>
			</g>
		);
	};

	return (
		<Box sx={{ p: 2 }}>
			<Grid container spacing={1.5}>
				<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
					{data && lastLogged && users ? (
						<>
							<Typography variant='h5' className='textColor'>
								All Users Status
							</Typography>{" "}
							<Typography variant='h6' sx={{ mb: 2 }} className='textColor'>
								Total : {users?.length}
							</Typography>
							<ResponsiveContainer width={"100%"} height={450}>
								<PieChart>
									<Pie
										activeIndex={activeIndexLastLogged}
										activeShape={renderActiveShape}
										data={data}
										cx='50%'
										cy='50%'
										innerRadius={100}
										outerRadius={130}
										fill='#8884d8'
										dataKey='value'
										labelLine={false}
										onMouseEnter={(data, index) =>
											setActiveIndexLastLogged(index)
										}
									/>
									<Legend
										layout='horizontal'
										verticalAlign='bottom'
										align='center'
										formatter={(value, entry) => (
											<span>
												{entry.payload.name}: {entry.payload.value}
											</span>
										)}
									/>
								</PieChart>
							</ResponsiveContainer>
						</>
					) : (
						<Box width={"100%"} height={450}>
							<CircularProgress />
						</Box>
					)}
				</Grid>

				{/* 	<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
					{invitedUsers && invitedUsersData ? (
						<>
							<Typography variant='h5' className='textColor'>
								Oppurtunities Status
							</Typography>
							<Typography variant='h6' sx={{ mb: 2 }} className='textColor'>
								Total : {invitedUsers?.length}
							</Typography>
							<ResponsiveContainer width={"100%"} height={450}>
								<PieChart>
									<Pie
										activeIndex={activeIndexInvitedUsers}
										activeShape={renderActiveShape}
										data={invitedUsersData}
										cx='50%'
										cy='50%'
										innerRadius={100}
										outerRadius={130}
										fill='#8884d8'
										dataKey='value'
										labelLine={false}
										onMouseEnter={(data, index) =>
											setActiveIndexInvitedUsers(index)
										}
									/>
									<Legend
										layout='horizontal'
										verticalAlign='bottom'
										align='center'
										formatter={(value, entry) => (
											<span>
												{entry.payload.name}: {entry.payload.value}
											</span>
										)}
									/>
								</PieChart>
							</ResponsiveContainer>
						</>
					) : (
						<Box width={"100%"} height={450}>
							<CircularProgress />
						</Box>
					)}
				</Grid> */}
			</Grid>
		</Box>
	);
};
export default Charts;
