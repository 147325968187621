import React from "react";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const CopyRight = () => {
	return (
		<div style={{ backgroundColor: "rgb(0 0 0 / 5%)" }}>
			<Divider className='borderColor' />
			<Typography
				variant='body1'
				component='div'
				className='textColor'
				sx={{ py: 1 }}>
				&copy;2024 All Right Reserved By <br />
				<b style={{ fontWeight: "bold" }}>  Digital Networking cards</b>
			</Typography>
		</div>
	);
};

export default CopyRight;
