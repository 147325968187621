import { Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h3'
				component='div'>
				OPPS! <br />
				404 Page Not Found
			</Typography>
		</div>
	);
};

export default NotFound;
