import {
	Backdrop,
	Card,
	CircularProgress,
	Container,
	Fade,
	Modal,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	maxWidth: "95vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const SingleLetter = ({ open, setOpen, view }) => {
	return (
		<Container>
			{view ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style}>
							<Box>
								<Card
									className='borderColor'
									sx={{
										p: 2,
										my: 1,
										minWidth: "300px",
										display: "flex",
										flexDirection: "column",
										alignContent: "center",
										overflow: "visible",
										border: "2px solid ",
										textAlign: "left",
										borderRadius: 3,
									}}>
									<CancelIcon
										onClick={() => setOpen(false)}
										className='textColor'
										sx={{
											position: "fixed",
											top: "13px",
											right: "5px",
											cursor: "pointer",
										}}
									/>
									<Box
										style={{
											maxHeight: "80vh",
											overflowY: "scroll",
										}}>
										<ul>
											{view?.to?.map((item, i) => (
												<li
													style={{
														listStyle: "disc",
													}}>
													{i + 1}-{item?.name}-{item?.phone}
												</li>
											))}
										</ul>
									</Box>
								</Card>
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</Container>
	);
};

export default SingleLetter;
