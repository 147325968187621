import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Fade,
	Grid,
	Modal,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ButtonsLink from "./ButtonsLink/ButtonsLink";
import ProfilePhoto from "./ProfilePhoto/ProfilePhoto";
import ReactPlayer from "react-player";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "70vw", sm: "80vw", xs: "95vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const NewUserNotice = ({ openNotice, setOpenNotice, data }) => {
	const [open, setOpen] = useState(false);
	const [notice, setNotice] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/welcomeNote`)
			.then((res) => res.json())
			.then((data) => {
				setNotice(data);
			});
	}, []);
	const [step, setStep] = useState(1);

	return (
		<Container component='main' maxWidth='xs'>
			{data && notice ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={openNotice}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={openNotice}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<Grid container spacing={3}>
								{step === 1 && (
									<Grid item md={12} sm={12} xs={12}>
										<Typography
											component='h1'
											variant='h5'
											className='textColor'
											sx={{ fontWeight: "bold" }}>
											Welcome {data?.headline?.title}
										</Typography>
										<Typography
											variant='body'
											className='textColor'
											sx={{ my: 3, whiteSpace: "pre-line" }}>
											{notice?.welcomeNote?.note}
										</Typography>
										<br />
										<Button
											className='confirm'
											onClick={() => setStep(step + 1)}
											variant='contained'
											sx={{
												mt: 2,
												width: "250px",
											}}>
											Next
										</Button>
									</Grid>
								)}
								{step === 2 && (
									<Grid item md={12} sm={12} xs={12}>
										<ProfilePhoto />
										<Button
											className='confirm'
											onClick={() => setStep(step + 1)}
											variant='contained'
											sx={{
												width: "250px",
											}}>
											Next
										</Button>
									</Grid>
								)}
								{step === 3 && (
									<Grid item md={12} sm={12} xs={12}>
										<ButtonsLink />
										<br />
										<Button
											className='confirm'
											onClick={() => setStep(step + 1)}
											variant='contained'
											sx={{
												mt: 2,
												width: "250px",
											}}>
											Next
										</Button>
									</Grid>
								)}
								{step === 4 && (
									<Grid item md={12} sm={12} xs={12}>
										{notice?.welcomeNote?.video?.trim() !== "" && (
											<ReactPlayer
												key={notice?.welcomeNote?.video}
												className='borderColor react-player'
												style={{ borderRadius: 11, backgroundColor: "black" }}
												width='100%'
												height='100%'
												url={notice?.welcomeNote?.video || ""}
												controls
											/>
										)}

										<br />
										<Button
											className='confirm'
											onClick={() => setStep(step + 1)}
											variant='contained'
											sx={{
												mt: 2,
												width: "250px",
											}}>
											Next
										</Button>
									</Grid>
								)}
							</Grid>

							{step === 5 && (
								<Button
									className='confirm'
									onClick={() => {
										setOpen(true);
										axios
											.put(
												`${process.env.REACT_APP_API_PATH}/updateNotificationStatus/${data?._id}`,
												{ status: true },
											)
											.then(function (response) {
												setOpenNotice(false);
												setOpen(false);
											})
											.catch(function (error) {
												console.log(error);
											});
									}}
									variant='contained'
									sx={{
										my: 10,
										width: "250px",
									}}>
									Continue to Dashboard
								</Button>
							)}
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
				open={open || !notice}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default NewUserNotice;
