import {
	Backdrop,
	Box,
	Container,
	Divider,
	Fab,
	Fade,
	Grid,
	Modal,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import SmsIcon from "@mui/icons-material/Sms";
import InstagramIcon from "@mui/icons-material/Instagram";
import PropagateLoader from "react-spinners/PropagateLoader";
import QRCode from "react-qr-code";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useAPI } from "../../../../apiContext";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const Buttons = () => {
	const { data, info } = useAPI();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const downloadTxtFile = () => {
		const element = document.createElement("a");
		const file = new Blob(
			[
				`
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${data?.headline?.title}
N;CHARSET=UTF-8:;${data?.headline?.title};;;
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${data?.email}
PHOTO;ENCODING=b;TYPE=X-ICON:IMAGEDATA..
TEL;TYPE=HOME,VOICE:${data?.phone || ""}
TITLE;CHARSET=UTF-8:${data?.headline?.subtitle} 
ORG;CHARSET=UTF-8:America Solar
URL;type=WORK;CHARSET=UTF-8:https://digitalbankingbusinesscard.com/@${data?.url}
END:VCARD
`,
			],
			{
				type: "text/x-vcard",
			},
		);
		element.href = URL.createObjectURL(file);
		element.download = `${data?.headline?.title}.vcf`;
		document.body.appendChild(element);
		element.click();
	};

	const [show, setShow] = useState(true);
	const url = window.location.pathname;
	useEffect(() => {
		if (url.includes("/dashboard")) {
			setShow(false);
		}
	}, [url]);

	return (
		<Container sx={{ pt: 5, "& .swiper-slide": { height: "auto" } }}>
			{data?._id ? (
				<>
					{show && (
						<Typography
							className='textColor'
							sx={{ mb: 0.5, fontWeight: 900 }}
							variant='h4'
							component='div'
							gutterBottom>
							{data?.headline?.buttons}
							<Divider className={`${info?.hr}`} />
						</Typography>
					)}
					<Grid container spacing={2} className='icons'>
						{data?.links?.facebookHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.facebook}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<FacebookOutlinedIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Facebook
								</Typography>
							</Grid>
						)}
						{data?.links?.emailHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`mailto:${data?.links?.email}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<EmailOutlinedIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Email
								</Typography>
							</Grid>
						)}
						{data?.links?.whatsappHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(
											`https://api.whatsapp.com/send?phone=${data?.links?.whatsapp}`,
											"_blank",
										)
									}
									color='primary'
									aria-label='add'>
									<WhatsAppIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Whatsapp
								</Typography>
							</Grid>
						)}
						{data?.links?.youtubeHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.youtube}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<SubscriptionsOutlinedIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Youtube
								</Typography>
							</Grid>
						)}
						{data?.links?.callHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() => window.open(`tel:${data?.links?.call}`)}
									color='primary'
									aria-label='add'>
									<LocalPhoneIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									CALL ME
								</Typography>
							</Grid>
						)}
						{data?.links?.smsHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`sms:${data?.links?.sms}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<SmsIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									TEXT ME
								</Typography>
							</Grid>
						)}
						{data?.links?.twitterHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.twitter}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<TwitterIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Twitter
								</Typography>
							</Grid>
						)}
						{data?.links?.instagramHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.instagram}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<InstagramIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Instagram
								</Typography>
							</Grid>
						)}
						{data?.links?.linkedinHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.linkedin}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<LinkedInIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									LinkedIn
								</Typography>
							</Grid>
						)}
						{data?.links?.pinterestHome === true && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.pinterest}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<PinterestIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Pinterest
								</Typography>
							</Grid>
						)}
						{data?.links?.alignableHome === true && (
							<Grid item xs>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() =>
										window.open(`${data?.links?.alignable}`, "_blank")
									}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/alignable.png"}
										alt='America Solar'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Alignable
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.nextdoorHome === true && (
							<Grid item xs>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() =>
										window.open(`${data?.links?.nextdoor}`, "_blank")
									}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/nextdoor.png"}
										alt='America Solar'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Nextdoor
									</Typography>
								</Box>
							</Grid>
						)}
						{data?.links?.zoomHome === true && (
							<Grid item xs>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => window.open(`${data?.links?.zoom}`, "_blank")}>
									<img
										className='borderColor'
										style={{
											width: "56px",
											height: "56px",
											border: "1px solid",
											borderRadius: "50%",
										}}
										src={"/zoom.png"}
										alt='America Solar'
									/>
									<Typography
										className='textColor'
										sx={{ mt: 1, fontWeight: "bold" }}
										variant='button'
										display='block'
										gutterBottom>
										Zoom
									</Typography>
								</Box>
							</Grid>
						)}
						{show && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab onClick={handleOpen} color='primary' aria-label='add'>
									<QrCode2Icon />
								</Fab>

								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									QR
								</Typography>
							</Grid>
						)}

						<Modal
							aria-labelledby='transition-modal-title'
							aria-describedby='transition-modal-description'
							open={open}
							onClose={handleClose}
							closeAfterTransition
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}>
							<Fade in={open}>
								<Box sx={style}>
									<Typography
										className='textColor'
										sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
										id='transition-modal-title'
										variant='h5'>
										Scan Me
									</Typography>
									<QRCode
										fgColor={`${info?.color1st}`}
										value={`${window.location.origin.toString()}/@${data?.url}`}
									/>
								</Box>
							</Fade>
						</Modal>
						{show && (
							<Grid className='contactList' item md={12} xs={12}>
								<Fab
									onClick={downloadTxtFile}
									variant='extended'
									sx={{
										px: 4,
										fontWeight: "bold",
									}}>
									<PermContactCalendarIcon sx={{ mr: 1.5 }} />
									Add to contact list
								</Fab>
							</Grid>
						)}
					</Grid>
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Buttons;
