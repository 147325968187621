import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import React from "react";
import { useAPI } from "../../../../apiContext";
import axios from "axios";

const Refferel = () => {
	const { data, info } = useAPI();
	const [submitting, setSubmitting] = React.useState(false);
	return (
		<Container sx={{ pt: 5 }}>
			<Typography
				className='textColor'
				sx={{
					mb: 0.5,
					fontWeight: 900,
					fontSize: { xs: "1.5rem", md: "2rem" },
				}}
				component='div'
				gutterBottom>
				Join now to be part of the change
				<p
					style={{
						fontSize: "1rem",
						marginTop: "10px",
					}}>
					Become a member now to receive limited time pre-launch benefits
				</p>
				<Divider className={`${info?.hr}`} />
			</Typography>

			<Grid
				container
				gap={2}
				sx={{
					mx: "auto",
				}}>
				<Grid
					item
					xs={12}
					md={4}
					sx={{
						mx: "auto",
					}}>
					<Box
						sx={{
							border: "2px solid #616161",
							borderRadius: "17px",
							paddingX: "20px",
						}}>
						<Box
							sx={{
								clipPath: "polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%)",
								backgroundColor: "#616161",
								padding: "21px",
								color: "white",
							}}>
							<Typography variant='h5'>Member</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: {
									xs: "column",
									sm: "row",
								},
								alignItems: "center",
								justifyContent: "center",
								color: "#616161",
							}}>
							<Box>
								<Typography variant='h3'>$50.00/yr.</Typography>
								<Typography
									variant='h6'
									sx={{
										margin: 0,
										fontWeight: 900,
									}}>
									Plus $1.99/mo.
								</Typography>
								<Typography variant='body1'>Allcards Subscription</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "10px",
								mt: 2,
							}}>
							<div>
								The BIGGER The Teams...The LOWER The Price
								<br />
								With our volume-based pricing, starting as low as $1.99 per
								month per user, we ensure that every member of your network can
								afford and continue to benefit from our digital card service.
								There are no contracts – billed annually. Plus, corporate setup
								support is available at no additional cost. Unlock the power of
								seamless networking at a remarkable low rate!
							</div>
						</Box>
						<Button
							onClick={() => {
								setSubmitting(true);
								axios
									.post(`${process.env.REACT_APP_API_PATH}/Clicks`, {
										url: data?.url,
										date: new Date().toISOString(),
										type: "Finmore Member",
										profileID: data?.refferel?.profileID,
									})
									.then((res) => {
										setSubmitting(false);
										window.open(
											/* `https://finmore.com/account/enroll?membershipType=FM&refID=${data?.refferel?.profileID}`, */
											"https://digitalnetworkingcards.com/ola/services/discover-call",
											"_blank",
										);
									})
									.catch((err) => {
										console.log(err);
									});
							}}
							sx={{
								fontWeight: "bold",
								border: "1px solid",
								backgroundColor: "#616161 !important",
								borderRadius: "25px",
								my: 2,
							}}
							variant='contained'>
							GET STARTED
						</Button>
					</Box>
				</Grid>
				{/* <Grid
					item
					xs={12}
					md={4}
					sx={{
						mx: "auto",
					}}>
					<Box
						sx={{
							border: "2px solid #2395BA",
							borderRadius: "17px",
							paddingX: "20px",
						}}>
						<Box
							sx={{
								clipPath: "polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%)",
								backgroundColor: "#2395BA",
								padding: "21px",
								color: "white",
							}}>
							<Typography variant='h5'>Finmore Business Owner</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: {
									xs: "column",
									sm: "row",
								},
								alignItems: "center",
								justifyContent: "center",
								color: "#2395BA",
							}}>
							<Box>
								<Typography variant='h3'>$199.00.</Typography>

								<Typography variant='body1'>one-time</Typography>
								<Typography variant='body1'>
									Does not include $50 Finmore Membership and AllCards
									subscription.
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "10px",
								mt: 2,
							}}>
							<div>Finmore Business Opportunity</div>
							<div>Commissions from customers you refer</div>
							<div>Marketing Materials</div>
							<div>Specialized FBO Trainings</div>
						</Box>
						<Button
							onClick={() => {
								setSubmitting(true);
								axios
									.post(`${process.env.REACT_APP_API_PATH}/Clicks`, {
										url: data?.url,
										date: new Date().toISOString(),
										type: "Finmore Business Owner",
										profileID: data?.refferel?.profileID,
									})
									.then((res) => {
										setSubmitting(false);
										window.open(
											`https://finmore.com/account/enroll?membershipType=FBO&refID=${data?.refferel?.profileID}`,
											"_blank",
										);
									})
									.catch((err) => {
										console.log(err);
									});
							}}
							sx={{
								fontWeight: "bold",
								border: "1px solid",
								backgroundColor: "#2395BA !important",
								borderRadius: "25px",
								my: 2,
							}}
							variant='contained'>
							GET STARTED
						</Button>
					</Box>
				</Grid> */}
			</Grid>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Refferel;
