import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { CircularProgress, Backdrop } from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import ButtonsLink from "../../ButtonsLink/ButtonsLink";
import RefferelManage from "../../RefferelManage/RefferelManage";
import ProfilePhoto from "../../ProfilePhoto/ProfilePhoto";
import Headline from "../../Headline/Headline";
import Details from "../../Details/Details";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "50vw", sm: "70vw", xs: "95vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const EditUser = ({ anotherUser, open, setOpen }) => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
						<CancelIcon
							onClick={() => setOpen(false)}
							className='textColor'
							sx={{
								position: "fixed",
								top: "5px",
								right: "5px",
								cursor: "pointer",
							}}
						/>
						<Box
							sx={{
								"& .Mui-selected": {
									backgroundColor: "#fff !important",
									color: "#000 !important",
								},
							}}>
							<Tabs
								value={value}
								onChange={handleChange}
								variant='scrollable'
								scrollButtons='auto'
								aria-label='scrollable auto tabs example'>
								<Tab label='Social Media Buttons' />
								<Tab label='Finmore Share ID' />
								<Tab label='Profile Photo' />
								<Tab label='Headlines' />
								<Tab label='Bio' />
							</Tabs>
							{value === 0 ? (
								<ButtonsLink anotherUser={anotherUser} />
							) : value === 1 ? (
								<RefferelManage anotherUser={anotherUser} />
							) : value === 2 ? (
								<ProfilePhoto anotherUser={anotherUser} />
							) : value === 3 ? (
								<Headline anotherUser={anotherUser} />
							) : (
								<Details anotherUser={anotherUser} />
							)}
						</Box>
					</Box>
				</Fade>
			</Modal>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!anotherUser}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default EditUser;
