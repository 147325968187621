import {
    Box,
    Typography,
    CardContent,
    Card,
    Grid,
    TextField,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAPI } from "../../../apiContext";

const Slides = () => {
    const category = "Slides";
    const { databyEmail } = useAPI();
    const [submitting, setSubmitting] = useState(false);
    const [pdfs, setPdfs] = useState([]);
    const [addNewMode, setAddNewMode] = useState(false);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_PATH}/AllPdfsByCategory/${category}`)
            .then((res) => res.json())
            .then((data) => {
                setPdfs(data);
            });
    }, [category, submitting, addNewMode]);
    const [datas, setDatas] = useState({
        title: "",
        url: "",
        details: "",
        category: category,
        type: "google-slides",
        submitTime: new Date().toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
        }),
    });

    const addNew = () => {
        setSubmitting(true);
        axios
            .post(`${process.env.REACT_APP_API_PATH}/AllPdfs`, { ...datas, category })
            .then(function (response) {
                setSubmitting(false);
                Swal.fire({
                    icon: "success",
                    title: "Successfully Posted",
                    showConfirmButton: true,
                    timer: 950000,
                });
                setDatas({
                    title: "",
                    url: "",
                    details: "",
                    category: category,
                    type: "google-slides",
                });
                setAddNewMode(false);
                setAddNewMode(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <Box
            sx={{
                p: 2,
            }}>
            <Typography
                variant='h4'
                gutterBottom
                className='textColor'
                sx={{ fontWeight: "bold" }}>
                Slides
            </Typography>
            {databyEmail?.role === "Admin" && (
                <Button
                    onClick={() => setAddNewMode(!addNewMode)}
                    sx={{
                        border: "2px solid",
                    }}>
                    {addNewMode ? "Cancel" : "Add New"}
                </Button>
            )}

            {addNewMode && databyEmail?.role === "Admin" ? (
                <Box>
                    <FormControl sx={{ my: 2 }}>
                        <FormLabel
                            id='demo-row-radio-buttons-group-label'
                            className='textColor'>
                            Link Type
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'>
                            <FormControlLabel
                                value='google-slides'
                                control={
                                    <Checkbox
                                        checked={datas.type === "google-slides"}
                                        onChange={(e) =>
                                            setDatas({ ...datas, type: e.target.value })
                                        }
                                        sx={{ p: 0, backgroundColor: "transparent", mr: 1 }}
                                    />
                                }
                                label='Google Slides'
                            />
                            <FormControlLabel
                                value='external'
                                control={
                                    <Checkbox
                                        checked={datas.type === "external"}
                                        onChange={(e) =>
                                            setDatas({ ...datas, type: e.target.value })
                                        }
                                        sx={{ p: 0, backgroundColor: "transparent", mr: 1 }}
                                    />
                                }
                                label='Others'
                            />
                        </RadioGroup>
                    </FormControl>
                    <p
                        style={{
                            color: "red",
                            fontWeight: "400",
                        }}>
                        If you want to add a slide from google slides, then copy the id from
                        the url and paste it here. Ex- If the url is like this -
                        <span>
                            https://docs.google.com/presentation/d/1TjPQ64Gy2nStTlAydefDGDvlOs6ZF-jDIJvmWiljJJc/edit?usp=sharing
                        </span>
                        , then the id is{" "}
                        <span>1TjPQ64Gy2nStTlAydefDGDvlOs6ZF-jDIJvmWiljJJc</span>
                        <br />
                        <br />
                        Othewise, you can add a slide from any other source by pasting the
                        url here.
                    </p>
                    <TextField
                        required
                        id='outlined-basic'
                        placeholder='Slides Url'
                        variant='outlined'
                        fullWidth
                        size='small'
                        sx={{
                            mb: 1,
                        }}
                        value={datas.url}
                        onChange={(e) => setDatas({ ...datas, url: e.target.value })}
                    />
                    <TextField
                        required
                        id='outlined-basic'
                        placeholder='Slides Title'
                        variant='outlined'
                        fullWidth
                        sx={{
                            mb: 1,
                        }}
                        size='small'
                        value={datas.title}
                        onChange={(e) => setDatas({ ...datas, title: e.target.value })}
                    />
                    <TextField
                        required
                        id='outlined-basic'
                        placeholder='Slides Details'
                        variant='outlined'
                        multiline
                        rows={3}
                        fullWidth
                        size='small'
                        sx={{
                            mb: 1,
                        }}
                        value={datas.details}
                        onChange={(e) => setDatas({ ...datas, details: e.target.value })}
                    />

                    <Button
                        onClick={() => addNew()}
                        sx={{
                            border: "2px solid",
                            px: 3,
                        }}>
                        {submitting ? "Submitting..." : "Add New SLide"}
                    </Button>
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {pdfs.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography
                                variant='h6'
                                gutterBottom
                                className='textColor'
                                sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    my: 5,
                                }}>
                                Opps!
                                <br />
                                No Slides Found!
                            </Typography>
                        </Grid>
                    ) : (
                        pdfs.map((pdf) => (
                            <Grid item md={4} sm={6} xs={12} key={pdf.id}>
                                <PdfCard
                                    pdf={pdf}
                                    setSubmitting={setSubmitting}
                                    submitting={submitting}
                                    databyEmail={databyEmail}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            )}
        </Box>
    );
};

export default Slides;

const PdfCard = ({ pdf, setSubmitting, submitting, databyEmail }) => {
    const [edit, setEdit] = React.useState(false);
    const [datas, setDatas] = useState();

    useEffect(() => {
        setDatas(pdf);
    }, [pdf]);

    const handleEdit = () => {
        setSubmitting(true);
        axios
            .put(`${process.env.REACT_APP_API_PATH}/AllPdfs/${pdf._id}`, datas)
            .then(function (response) {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: true,
                    timer: 950000,
                });
                setEdit(false);
                setSubmitting(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this slide!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.isConfirmed) {
                setSubmitting(true);
                axios
                    .delete(`${process.env.REACT_APP_API_PATH}/AllPdfs/${pdf._id}`)
                    .then(function (response) {
                        Swal.fire({
                            icon: "success",
                            title: "Successfully Deleted",
                            showConfirmButton: true,
                            timer: 950000,
                        });
                        setSubmitting(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        });
    };

    return (
        <Card
            className='border'
            sx={{
                "&:hover": { boxShadow: 5 },
                cursor: "pointer",
                border: 0.3,
            }}>
            <Box
                sx={{
                    width: "100%",
                    height: edit
                        ? "auto"
                        : {
                            sm: "390px !important",
                            xs: "350px !important",
                        },
                }}>
                {edit ? (
                    <Box
                        sx={{
                            p: 2,
                        }}>
                        <FormControl>
                            <FormLabel
                                id='demo-row-radio-buttons-group-label'
                                className='textColor'>
                                Link Type
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='row-radio-buttons-group'>
                                <FormControlLabel
                                    value='google-slides'
                                    control={
                                        <Checkbox
                                            checked={datas.type === "google-slides"}
                                            onChange={(e) =>
                                                setDatas({ ...datas, type: e.target.value })
                                            }
                                            sx={{ p: 0, backgroundColor: "transparent", mr: 1 }}
                                        />
                                    }
                                    label='Google Slides'
                                />
                                <FormControlLabel
                                    value='external'
                                    control={
                                        <Checkbox
                                            checked={datas.type === "external"}
                                            onChange={(e) =>
                                                setDatas({ ...datas, type: e.target.value })
                                            }
                                            sx={{ p: 0, backgroundColor: "transparent", mr: 1 }}
                                        />
                                    }
                                    label='Others'
                                />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            value={datas?.url}
                            onChange={(e) => setDatas({ ...datas, url: e.target.value })}
                            id='outlined-basic'
                            placeholder='Slides Url'
                            variant='outlined'
                            fullWidth
                            multiline
                            rows={3}
                            size='small'
                            sx={{
                                mt: 1,
                            }}
                        />
                        <p
                            style={{
                                color: "red",
                                fontWeight: "400",
                            }}>
                            If you want to add a Slides from google slides, then copy the id
                            from the url and paste it here. Ex- If the url is like this -
                            <span>
                                https://docs.google.com/presentation/d/1TjPQ64Gy2nStTlAydefDGDvlOs6ZF-jDIJvmWiljJJc/edit?usp=sharing
                            </span>
                            , then the id is{" "}
                            <span>1TjPQ64Gy2nStTlAydefDGDvlOs6ZF-jDIJvmWiljJJc</span>
                            <br />
                            <br />
                            Othewise, you can add a Slides from any other source by pasting
                            the url here.
                        </p>
                    </Box>
                ) : (
                    <iframe
                        src={
                            pdf?.type === "google-slides"
                                ? `https://drive.google.com/file/d/${pdf?.url}/preview`
                                : pdf?.url
                        }
                        title='pdf'
                        width='100%'
                        height='100%'
                    />
                )}
            </Box>
            <CardContent
                className='border'
                sx={{
                    position: "relative",
                    backgroundColor: "white",
                    borderTop: 1,
                }}>
                {edit ? (
                    <TextField
                        onChange={(e) => setDatas({ ...datas, title: e.target.value })}
                        value={datas?.title}
                        id='outlined-basic'
                        placeholder='Slides Title'
                        variant='outlined'
                        fullWidth
                        sx={{
                            mb: 1,
                        }}
                        size='small'
                    />
                ) : (
                    <Typography
                        className='color'
                        gutterBottom
                        sx={{
                            fontSize: 15,
                            fontWeight: "bold",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                        color='text.secondary'>
                        {pdf?.title}
                    </Typography>
                )}
                {edit ? (
                    <TextField
                        onChange={(e) => setDatas({ ...datas, details: e.target.value })}
                        value={datas?.details}
                        id='outlined-basic'
                        placeholder='Slides Details'
                        variant='outlined'
                        multiline
                        rows={4}
                        fullWidth
                        size='small'
                    />
                ) : (
                    <Typography
                        className='textOverflow'
                        variant='body'
                        color='text.secondary'>
                        {pdf?.details}
                    </Typography>
                )}

                {databyEmail?.role === "Admin" && (
                    <ButtonGroup
                        sx={{
                            mt: 1,
                            display: "block",
                        }}>
                        <Button onClick={() => setEdit(!edit)}>
                            {edit ? "Cancel" : "Edit"}
                        </Button>
                        <Button onClick={handleDelete}>
                            <DeleteIcon />
                        </Button>
                        {edit && <Button onClick={handleEdit}>Save</Button>}
                    </ButtonGroup>
                )}
            </CardContent>
        </Card>
    );
};
