import React from "react";

const buttonStyle = {
	padding: "8px 12px",
	background: "var(--color1st)",
	color: "white",
	border: "none",
	borderRadius: "4px",
	cursor: "pointer",
	outline: "none",
};

const disabledButtonStyle = {
	...buttonStyle,
	background: "#DDDDDD",
	cursor: "not-allowed",
};

const selectStyle = {
	padding: "8px 12px",
	border: "1px solid var(--color1st)",
	borderRadius: "4px",
	cursor: "pointer",
	outline: "none",
};

function Pagination({ totalItems, onPageChange, itemsPerPage, currentPage }) {
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handlePageClick = (page) => {
		if (page >= 1 && page <= totalPages) {
			onPageChange(page);
		}
	};

	const handleItemsPerPageChange = (event) => {
		const newItemsPerPage = parseInt(event.target.value, 10);
		onPageChange(1, newItemsPerPage);
	};

	const renderPageNumbers = () => {
		const pageNumbers = [];
		const displayPages = 5;

		let startPage = currentPage - displayPages;
		if (startPage < 1) {
			startPage = 1;
		}

		let endPage = currentPage + displayPages;
		if (endPage > totalPages) {
			endPage = totalPages;
		}

		for (let i = startPage; i <= endPage; i++) {
			const isActive = i === currentPage;
			pageNumbers.push(
				<button
					key={i}
					style={{
						...buttonStyle,
						background: isActive ? " var(--color1st)" : "#DDDDDD",
						color: isActive ? "white" : "black",
						border: "1px solid var(--color1st)",
						margin: "0 1.5px",
					}}
					onClick={() => handlePageClick(i)}>
					{i}
				</button>,
			);
		}

		return pageNumbers;
	};

	return (
		<div>
			<button
				onClick={() => handlePageClick(currentPage - 1)}
				disabled={currentPage === 1}
				style={currentPage === 1 ? disabledButtonStyle : buttonStyle}>
				Previous
			</button>
			{renderPageNumbers()}
			<button
				onClick={() => handlePageClick(currentPage + 1)}
				disabled={currentPage === totalPages}
				style={currentPage === totalPages ? disabledButtonStyle : buttonStyle}>
				Next
			</button>
			<span
				style={{
					color: "var(--color1st)",
					marginLeft: "3px",
				}}>
				Items per page:
				<select
					value={itemsPerPage}
					onChange={handleItemsPerPageChange}
					style={selectStyle}>
					<option value={10}>10</option>
					<option value={20}>20</option>
					<option value={50}>50</option>
					<option value={100}>100</option>
				</select>
				<span
					style={{
						color: "var(--color1st)",
						marginLeft: "3px",
					}}>
					of {totalItems}
				</span>
			</span>
		</div>
	);
}

export default Pagination;
