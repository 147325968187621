import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Review from "./Review";
import GetAuth from "../../../GetAuth";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useAPI } from "../../../apiContext";
import AddReview from "../../Homepage/Components/Reviews/AddReview";

const AllReviews = () => {
	const [addReview, setAddReview] = useState(false);
	const { databyEmail } = useAPI();
	const [open, setOpen] = React.useState(false);
	const [viewId, setViewId] = useState();
	const handleOpen = (reviewViewId) => {
		setViewId(reviewViewId);
		setOpen(true);
	};
	const [reviews, setReviews] = useState([]);
	const [deleted, setDeleted] = useState(false);
	const [user] = GetAuth();
	useEffect(() => {
		fetch(
			databyEmail?.role === "Admin"
				? `${process.env.REACT_APP_API_PATH}/alluserReview/allreviews`
				: `${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setReviews(
					databyEmail?.role === "Admin"
						? data?.reverse()
						: data?.reviews?.reverse(),
				);
			});
	}, [deleted, user, databyEmail, addReview]);

	const handleDelete = (deletedReview) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setDeleted(true);
				axios
					.put(
						`${process.env.REACT_APP_API_PATH}/deleteReview/${deletedReview?.revewFor}/${deletedReview?.reviewId}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That Review has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const columns = [
		{
			field: "Photo",
			headerName: "Photo",
			minWidth: 150,
			renderCell: (params) => {
				return (
					<img
						src={params?.row?.userPhoto || "/user.jpg"}
						alt=''
						width='50px'
						height='50px'
						style={{ borderRadius: "50%" }}
					/>
				);
			},
		},

		{
			field: "userName",
			headerName: "Name",
			flex: 1,
		},
		{
			field: "rating",
			headerName: "Star",
			flex: 1,
		},
		{
			field: "submitTime",
			headerName: "Rec Time",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 170,
			filterable: false,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() => handleOpen(params?.row)}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => handleDelete(params?.row)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</>
				);
			},
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				All Reviews
			</Typography>

			{addReview && (
				<AddReview open={addReview} setOpen={setAddReview} data={databyEmail} />
			)}
			{databyEmail?.role === "Admin" && (
				<Button
					onClick={() => setAddReview(true)}
					variant='contained'
					sx={{
						mt: 2,
						px: 3,
						fontWeight: "bold",
						borderRadius: "25px",
						backgroundColor: "transparent",
						border: "2px solid",
					}}>
					Add Review
				</Button>
			)}
			{reviews && (
				<Box sx={{ width: "100%", height: "75vh" }}>
					<DataGrid
						rows={reviews}
						getRowId={(row) => row?.reviewId}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!reviews}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && <Review open={open} setOpen={setOpen} data={viewId} />}
		</Box>
	);
};

export default AllReviews;
