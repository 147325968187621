import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Mail from "./Mail";
import GetAuth from "../../../GetAuth";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useAPI } from "../../../apiContext";
import SmsIcon from "@mui/icons-material/Sms";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SendSms from "./SendSms";
import Note from "./Note";

const AllMails = () => {
	const [open, setOpen] = React.useState(false);
	const [viewId, setViewId] = useState();
	const handleOpen = (viewMailId) => {
		setViewId(viewMailId);
		setOpen(true);
	};

	const { databyEmail } = useAPI();
	const [deleted, setDeleted] = useState(false);
	const [mails, setMails] = useState();

	const [user] = GetAuth();
	useEffect(() => {
		fetch(
			databyEmail?.role === "Admin"
				? `${process.env.REACT_APP_API_PATH}/alluseremails/allemails`
				: `${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setMails(
					databyEmail?.role === "Admin"
						? data?.reverse()
						: data?.emails?.reverse(),
				);
			});
	}, [deleted, user?.email, databyEmail]);
	const handleDelete = (deletedMail) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(
						`${process.env.REACT_APP_API_PATH}/deleteMail/${deletedMail?.mailFor}/${deletedMail?.mailId}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That mail has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const [openNote, setOpenNote] = useState(false);
	const [users, setUsers] = useState();
	const [openSms, setOpenSms] = useState(false);

	const columns = [
		{
			field: "userName",
			headerName: "Name",
			flex: 1,
		},
		{
			field: "submitTime",
			headerName: "Rec Time",
			flex: 1,
		},
		{
			field: "phoneNumber",
			headerName: "Phone",
			flex: 1,
		},
		{
			field: "userEmail",
			headerName: "Email",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 310,
			filterable: false,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() => {
								setOpenNote(true);
								setUsers(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>

						{params?.row?.sentSms ? (
							<div
								style={{
									color: "red",
									textAlign: "center",
									fontWeight: "bold",
								}}>
								SMS Sent
							</div>
						) : (
							<Button
								className='button border'
								onClick={() => {
									setUsers(params?.row);
									setOpenSms(true);
								}}
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<SmsIcon />
							</Button>
						)}
						<Button
							onClick={() => handleOpen(params?.row)}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => handleDelete(params?.row)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</>
				);
			},
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				All Entry Form Leads
			</Typography>
			{mails && (
				<Box sx={{ width: "100%", height: "75vh" }}>
					<DataGrid
						rows={mails}
						getRowId={(row) => row?.mailId}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!mails}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && <Mail open={open} setOpen={setOpen} data={viewId} />}
			{openSms && <SendSms open={openSms} setOpen={setOpenSms} datas={users} />}

			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} datas={users} />
			)}
		</Box>
	);
};

export default AllMails;
