import {
	Backdrop,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	CircularProgress,
	Container,
	Fade,
	Grid,
	Modal,
	Typography,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import ReactPlayer from "react-player";
import { useAPI } from "../../../apiContext";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	width: { md: "40vw", sm: "60vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const SIngleView = ({ open, setOpen, view }) => {
	const { databyEmail } = useAPI();
	return (
		<Container>
			{databyEmail ? (
				<Modal
					sx={{ backgroundColor: "transparent" }}
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<Card
								elevation={0}
								sx={{
									backgroundColor: "transparent",
									p: 2,
									my: 1,
									minWidth: "300px",
									display: "flex",
									flexDirection: "column",
									alignContent: "center",
									overflow: "visible",
									textAlign: "left",
									borderRadius: 3,
								}}>
								<CancelIcon
									onClick={() => setOpen(false)}
									className='textColor'
									sx={{
										position: "fixed",
										top: "13px",
										right: "5px",
										cursor: "pointer",
									}}
								/>
								<Card elevation={0} sx={{ backgroundColor: "transparent" }}>
									<CardHeader
										className='textColor'
										sx={{ textAlign: "center", fontWeight: "bold" }}
										title={view?.title}
										subheader={view?.submitTime}
									/>
									<Grid container spacing={2} sx={{ justifyContent: "center" }}>
										{view?.video && (
											<Grid item md={12} xs={12}>
												{view?.video?.trim() !== "" && (
													<ReactPlayer
														key={view?.video}
														className='borderColor react-player'
														style={{
															borderRadius: 11,
															backgroundColor: "black",
														}}
														width='100%'
														height='100%'
														url={view?.video}
														controls
													/>
												)}
											</Grid>
										)}
										{view?.image1 && (
											<Grid item md={6} xs={12}>
												<CardMedia
													component='img'
													height='194'
													image={view?.image1}
												/>
											</Grid>
										)}
										{view?.image2 && (
											<Grid item md={6} xs={12}>
												<CardMedia
													component='img'
													height='194'
													image={view?.image2}
												/>
											</Grid>
										)}
										{view?.image3 && (
											<Grid item md={6} xs={12}>
												<CardMedia
													component='img'
													height='194'
													image={view?.image3}
												/>
											</Grid>
										)}
										{view?.image4 && (
											<Grid item md={6} xs={12}>
												<CardMedia
													component='img'
													height='194'
													image={view?.image4}
												/>
											</Grid>
										)}
									</Grid>

									<CardContent sx={{ mt: 0.5 }}>
										<Typography variant='body2' sx={{ whiteSpace: "pre-line" }}>
											{view?.news}
										</Typography>
									</CardContent>
								</Card>
							</Card>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</Container>
	);
};

export default SIngleView;
