import { Container, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import SwiperCore, { Autoplay, Pagination, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Gallery.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";
SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

const Gallery = () => {
	const [gallery, setGallery] = useState([]);
	const { data, info } = useAPI();
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/gallery`)
			.then((res) => res.json())
			.then((data) => setGallery(data));
	}, []);

	return (
		<Container sx={{ pt: 5, "& .swiper-slide": { height: "auto" } }}>
			{gallery?.length > 0 ? (
				<>
					{data?._id && data?.headline?.gallery ? (
						<>
							{data?.headline?.gallery === "off" ? (
								""
							) : (
								<>
										<Typography
											className='textColor'
											sx={{
												mb: 0.5,
												fontWeight: 900,
												fontSize: { xs: "1.5rem", md: "2rem" },
											}}
											component='div'
											gutterBottom>
										{data?.headline?.gallery}
										<Divider className={`${info?.hr}`} />
									</Typography>
									{gallery && (
										<Swiper
											effect={"coverflow"}
											autoHeight={true}
											grabCursor={true}
											loop={true}
											autoplay={{ delay: 2500 }}
											centeredSlides={true}
											slidesPerView={"auto"}
											initialSlide={1}
											coverflowEffect={{
												rotate: 50,
												stretch: 0,
												depth: 100,
												modifier: 1,
												slideShadows: false,
											}}
											pagination={true}>
											{gallery?.map((a, key) => (
												<SwiperSlide key={key}>
													<img src={a?.imageLink2} alt='' />
												</SwiperSlide>
											))}
										</Swiper>
									)}
								</>
							)}
						</>
					) : (
						<div className='loader'>
							<PropagateLoader size={11} />
						</div>
					)}
				</>
			) : (
				<></>
			)}
		</Container>
	);
};

export default Gallery;
