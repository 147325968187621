import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Button,
	Chip,
	CircularProgress,
	Container,
	FormHelperText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import GetAuth from "../../../GetAuth";

const ColorChange = () => {
	const [submitting, setSubmitting] = useState(false);
	const { handleSubmit, reset } = useForm({
		defaultValues: {
			color: "",
		},
	});
	const [info, setInfo] = useState();
	const [user] = GetAuth();
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_PATH}/colorBorder`).then((res) => {
			reset(res.data?.colorBorder);
			setInfo(res.data?.colorBorder);
		});
	}, [reset, user?.email]);
	const [hr, setHr] = React.useState(info?.hr);

	const handleChange = (event) => {
		setHr(event.target.value);
	};
	const [color, setColor] = useState(info?.color1st);
	const onSubmit = () => {
		setSubmitting(true);
		const data = {
			type: "colorBorder",
			color1st: color || info?.color1st,
			hr: hr || info?.hr,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		axios
			.put(`${process.env.REACT_APP_API_PATH}/colorBorder`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Change Color And Border Style
			</Typography>
			<Typography variant='body' gutterBottom className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					spacing={3}
					display='flex'
					flexDirection='column'
					alignContent='center'
					sx={{ mt: 5 }}>
					<Grid item md={8} sm={8} xs={12}>
						<Chip label='Click below to change color' sx={{ mb: 1 }} />
						<input
							style={{ width: "100%", height: "57px" }}
							type='color'
							value={color || info?.color1st}
							onChange={(e) => setColor(e.target.value)}
						/>
						<input
							style={{ width: "100%", height: "45px", padding: "0 1rem" }}
							type='text'
							value={color || info?.color1st}
							onChange={(e) => setColor(e.target.value)}
						/>
					</Grid>
					<Grid item md={8} sm={8} xs={12}>
						<FormHelperText>Change Border Style</FormHelperText>
						<FormControl
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-input": { padding: "9px 14px 0px 14px" },
							}}>
							<Select
								value={hr}
								onChange={handleChange}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}>
								<MenuItem id='borderChoose'>
									<hr className={`${info?.hr}`} style={{ width: "100%" }} />
								</MenuItem>
								<MenuItem value='hr' id='borderChoose'>
									<hr className='hr' />
								</MenuItem>
								<MenuItem value='hr2' id='borderChoose'>
									<hr className='hr2' />
								</MenuItem>
								<MenuItem value='hr3' id='borderChoose'>
									<hr className='hr3' />
								</MenuItem>
								<MenuItem value='hr4' id='borderChoose'>
									<hr className='hr4' />
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ColorChange;
