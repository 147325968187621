import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Box,
	IconButton,
	Button,
	CircularProgress,
	Container,
	Grid,
} from "@mui/material";
import Swal from "sweetalert2";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";

import { styled } from "@mui/material/styles";
import GetAuth from "../../../GetAuth";

const ProfilePhoto = ({ anotherUser }) => {
	const [id, setId] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [imageLink2, setImageLink2] = useState(null);

	const Input = styled("input")({
		display: "none",
	});
	const [profile, setProfile] = React.useState();
	const [user] = GetAuth();
	React.useEffect(() => {
		fetch(
			anotherUser
				? `${process.env.REACT_APP_API_PATH}/userbyemail/${anotherUser?.email}`
				: `${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setId(data?._id);
				setProfile(data?.profile);
			});
	}, [user?.email, anotherUser]);
	const [photo, setPhoto] = useState(profile?.imageLink2);
	React.useEffect(() => {
		setPhoto(profile?.imageLink2);
	}, [profile?.imageLink2, submitting]);
	const profilephoto = {
		submitTime: new Date().toLocaleString("en-US", {
			timeZone: "America/Los_Angeles",
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			timeZoneName: "short",
		}),
		type: "profile",
		imageLink2: imageLink2 || photo,
	};

	const update = () => {
		setSubmitting(true);
		axios
			.put(`${process.env.REACT_APP_API_PATH}/profile/${id}`, profilephoto)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your photo updated Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
				setPhoto(profilephoto?.imageLink2);
				setImageLink2("");
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "Profile");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/tranzact-card/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Update Profile Picture
			</Typography>
			<Typography variant='body' gutterBottom className='textColor'>
				Last changed in {profile?.submitTime || "N/A"}
			</Typography>
			<Grid
				container
				spacing={0}
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{ minHeight: "70vh" }}>
				<img src={photo} style={{ maxWidth: "300px", width: "100%" }} alt='' />
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					sx={{ my: 1, mx: "auto" }}>
					<label
						className='bgColor'
						htmlFor='icon-button-file'
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							margin: "0 9px",
							borderRadius: 5,
						}}>
						<Input
							accept='image/*'
							id='icon-button-file'
							type='file'
							onChange={uploadImage}
						/>
						<Typography
							sx={{ my: 2, px: 1, color: "white" }}
							variant='body1'
							component='div'
							gutterBottom>
							Update Profile Picture*
						</Typography>
						<IconButton
							color='primary'
							aria-label='upload picture'
							component='span'>
							<AttachFileIcon fontSize='large' sx={{ fontWeight: "bold" }} />
						</IconButton>
					</label>

					{loading ? (
						<Box sx={{ my: 2 }}>
							<CircularProgress className='textColor' />
						</Box>
					) : (
						<img
							src={imageLink2}
							style={{ maxWidth: "300px", width: "100%" }}
							alt=''
						/>
					)}
				</Box>
				{imageLink2 && (
					<Button
						onClick={update}
						variant='contained'
						component='span'
						className='button border'
						sx={{
							my: 1,
							py: 0.5,
							width: "150px",
							border: "2px solid",
							backgroundColor: "transparent",
						}}>
						Update
					</Button>
				)}
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !profile}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ProfilePhoto;
