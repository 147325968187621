import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	Grid,
	TextField,
	Box,
	Backdrop,
	CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useForm } from "react-hook-form";
import GetAuth from "../../../GetAuth";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AddVideo = () => {
	const [submitting, setSubmitting] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const [videos, setVideos] = useState();
	const [user] = GetAuth();
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/collectionOfVideos`)
			.then((res) => res.json())
			.then((data) => {
				setVideos(data.reverse());
			});
	}, [submitting, deleted, user?.email]);
	const onSubmit = ({ videoTitle, videoLink }) => {
		setSubmitting(true);
		const data = {
			videoTitle,
			videoLink,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			videoId: `video${Math.floor(100000 + Math.random() * 900000)}`,
		};
		axios
			.post(`${process.env.REACT_APP_API_PATH}/collectionOfVideos`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Added",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setDeleted(true);
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/collectionOfVideos/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That video has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const columns = [
		{
			field: "videoTitle",
			headerName: "Title",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 170, filterable: false,
			renderCell: (params) => {
				return (
					<Button
						onClick={() => handleDelete(params?.row?._id)}
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
						}}
						variant='contained'>
						<DeleteIcon />
					</Button>
				);
			},
		},
	];
	const [open, setOpen] = useState(false);
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				Post New Video
			</Typography>
			<Button
				onClick={() => (open ? setOpen(false) : setOpen(true))}
				variant='contained'
				type='submit'
				sx={{
					mb: 1,
					px: 3,
					fontWeight: "bold",
					borderRadius: "25px",
					backgroundColor: open && "red !important",
				}}>
				{open ? "Close" : "Add New"}
			</Button>
			{open && (
				<form onSubmit={handleSubmit(onSubmit)} method='post'>
					<Grid spacing={1} container>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='videoLink'
								label='Video Link'
								size='small'
								{...register("videoLink", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='videoTitle'
								label='Video Title'
								size='small'
								{...register("videoTitle", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<Button
								className='sendButton'
								type='submit'
								variant='contained'
								sx={{
									width: "100%",
									mb: 2,
									px: 3,
									fontWeight: "bold",
									borderRadius: "25px",
								}}>
								Post
							</Button>
						</Grid>
					</Grid>
				</form>
			)}
			{videos && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={videos}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}
			{/* 	<Paper
				className='container'
				sx={{
					overflow: "auto",
					maxHeight: "85vh",
					maxWidth: "90vw",
					m: 2,
					width: "100%",
				}}>
				<Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='center' colSpan={4}>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold" }}
									variant='h4'>
									All Videos
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>Title</TableCell>
							<TableCell align='left'>Action</TableCell>
						</TableRow>
					</TableHead>
					{videos?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{videos?.map((video) => (
								<TableRow
									key={video?._id}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell align='left'>{video?.videoTitle}</TableCell>
									<TableCell align='left' className='actionButton'>
										<Button
											onClick={() => handleDelete(video?._id)}
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
											}}
											variant='contained'>
											<DeleteIcon />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper> */}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!videos || deleted}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default AddVideo;
