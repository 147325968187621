import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { Link, Outlet } from "react-router-dom";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import PortraitIcon from "@mui/icons-material/Portrait";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FeedIcon from "@mui/icons-material/Feed";
import InfoIcon from "@mui/icons-material/Info";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PostAddIcon from "@mui/icons-material/PostAdd";
import StorageIcon from "@mui/icons-material/Storage";
import { Avatar, Badge, Button, CardMedia } from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PeopleIcon from "@mui/icons-material/People";
import ShareIcon from "@mui/icons-material/Share";
import { signOut } from "firebase/auth";
import auth from "../../Firebase/firebase.config";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Navigate } from "react-router-dom";
import GavelIcon from "@mui/icons-material/Gavel";
import useDataByEmail from "./useDataByEmail";
import FaceIcon from "@mui/icons-material/Face";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import MailIcon from "@mui/icons-material/Mail";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import NewUserNotice from "./NewUserNotice";
import GetAuth from "../../GetAuth";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import io from "socket.io-client";
import Swal from "sweetalert2";
import UnreadChat from "./UnreadChat";
import BarChartIcon from "@mui/icons-material/BarChart";
import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";

const drawerWidth = 275;

const socket = io(`${process.env.REACT_APP_API_PATH}`);

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === "dark"
			? "rgba(255, 255, 255, .05)"
			: "rgba(0, 0, 0, .03)",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
}));

function Dashboard(props) {
	const [data] = useDataByEmail();
	const [lastVisitDate, setLastVisitDate] = React.useState();
	React.useEffect(() => {
		setLastVisitDate(reactLocalStorage.get("lastVisitDate"));
	}, []);

	React.useEffect(() => {
		const currentDate = new Date().toLocaleDateString();
		if (data && lastVisitDate !== currentDate) {
			axios
				.put(`${process.env.REACT_APP_API_PATH}/lastVisited/${data?.url}`, {
					userName: data?.url,
					lastVisited: new Date().toLocaleString("en-US", {
						timeZone: "America/Los_Angeles",
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
						second: "2-digit",
						timeZoneName: "short",
					}),
				})
				.then(function (response) {
					setLastVisitDate(currentDate);
					reactLocalStorage.set("lastVisitDate", currentDate);
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	}, [data, lastVisitDate]);
	React.useEffect(() => {
		if (data?.url) {
			socket.emit("join_room", data?.url);
		}
	}, [data]);
	function playSound() {
		const audio = new Audio("/beep.mp3");
		audio.play();
	}
	React.useEffect(() => {
		socket.on("alert_rec", (data) => {
			data?.alert === true &&
				Swal.fire({
					icon: "alert",
					title: "New message",
					showConfirmButton: false,
					timer: 1500,
				});
			playSound();
		});
	}, []);

	const location = useLocation();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const [expanded, setExpanded] = React.useState("");

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const drawer = (
		<Box className='dashboard'>
			<Typography
				sx={{
					py: 1.5,
					color: "white",
					width: "100%",
					fontWeight: "bold",
					fontSize: "17px !important",
				}}>
				Digital Networking cards
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					py: 1,
				}}>
				<Avatar
					sx={{ border: "2px solid white", mb: 0.7 }}
					alt=''
					src={data?.profile?.imageLink2}
				/>
				<Typography sx={{ color: "white", fontSize: "17px !important" }}>
					{data?.headline?.title}
				</Typography>
				<Typography sx={{ color: "white" }}>{data?.role}</Typography>
			</Box>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to=''>
				<ListItem
					button
					className={location?.pathname === "/dashboard" && "activeButton"}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<FaceIcon />
					</ListItemIcon>
					<ListItemText primary={"My Profile"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='RefferelManage'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/RefferelManage" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<RoomPreferencesIcon />
					</ListItemIcon>
					<ListItemText primary={"Your Sign-Up Share Links"} />
				</ListItem>
			</Link>
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='Charts'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/Charts" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<BarChartIcon />
						</ListItemIcon>
						<ListItemText primary={"Charts"} />
					</ListItem>
				</Link>
			)}
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='tutorials'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/tutorials" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<CastForEducationIcon />
					</ListItemIcon>
					<ListItemText primary={"Quick Start Guide"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='trainings'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/trainings" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ModelTrainingIcon />
					</ListItemIcon>
					<ListItemText primary={"Trainings"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='Book'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/Book" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ModelTrainingIcon />
					</ListItemIcon>
					<ListItemText primary={"Book"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='Slides'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/Slides" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ModelTrainingIcon />
					</ListItemIcon>
					<ListItemText primary={"Slides"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='allposts'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/allposts" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<PostAddIcon />
					</ListItemIcon>
					<ListItemText primary={"Social Media Posts"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='viewnews'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/viewnews" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<AnnouncementIcon />
					</ListItemIcon>
					<ListItemText primary={"News"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='MemoryJoggerList'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/MemoryJoggerList" &&
						"activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<StorageIcon />
					</ListItemIcon>
					<ListItemText primary={"My List"} />
				</ListItem>
			</Link>
			<Accordion
				sx={{
					border: "1px solid #fff",
					borderRadius: "15px",
					marginBottom: "5px",
					overflow: "hidden",
					"& .MuiAccordionSummary-root": {
						width: "100% !important",
					},
				}}
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}>
				<AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
					<ListItemIcon className='listItemIcon'>
						<CardMembershipIcon />
					</ListItemIcon>
					<Typography>Card Setting</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<List>
						{data?.role === "Admin" && (
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='bannerphoto'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/bannerphoto" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<PhotoCameraBackIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Banner Photo"} />
								</ListItem>
							</Link>
						)}
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='profilephoto'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/profilephoto" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<PortraitIcon />
								</ListItemIcon>
								<ListItemText primary={"Profile Photo"} />
							</ListItem>
						</Link>

						{data?.role === "Admin" && (
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='galleryphoto'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/galleryphoto" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<ViewCarouselIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Gallery Photos"} />
								</ListItem>
							</Link>
						)}
						{data?.role === "Admin" && (
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='managevideo'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/managevideo" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<OndemandVideoIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Gallery Videos"} />
								</ListItem>
							</Link>
						)}
						{data?.role === "Admin" && (
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='colorchange'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/colorchange" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<ColorLensIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Color & Border"} />
								</ListItem>
							</Link>
						)}
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='headline'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/headline" && "activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<FeedIcon />
								</ListItemIcon>
								<ListItemText primary={"Headline"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='buttonslink'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/buttonslink" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<PostAddIcon />
								</ListItemIcon>
								<ListItemText primary={"Social Media Profiles"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='details'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/details" && "activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<InfoIcon />
								</ListItemIcon>
								<ListItemText primary={"Bio"} />
							</ListItem>
						</Link>
					</List>
				</AccordionDetails>
			</Accordion>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='allreviews'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/allreviews" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ReviewsIcon />
					</ListItemIcon>
					<ListItemText primary={"All Reviews"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='allemails'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/allemails" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ContactMailIcon />
					</ListItemIcon>
					<ListItemText primary={"Entry Form Leads"} />
				</ListItem>
			</Link>{" "}
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='Planner'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/Planner" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<EventNoteIcon />
					</ListItemIcon>
					<ListItemText primary={"Planner"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='faq'>
				<ListItem
					button
					className={location?.pathname === "/dashboard/faq" && "activeButton"}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<LiveHelpIcon />
					</ListItemIcon>
					<ListItemText primary={"FinMore Faq"} />
				</ListItem>
			</Link>
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='AllClicks'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/AllClicks" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PeopleIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Clicks"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allusers'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/allusers" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PeopleIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Users"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='BlockedScheduleDate'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/BlockedScheduleDate" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<EventBusyIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Manage Schedule Date & Time"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='meetings'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/meetings" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ScheduleIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Scheduled Meetigs"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='meetingSucceed'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/meetingSucceed" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ScheduleIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Succeed Meetings"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='managefaq'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/managefaq" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<LiveHelpIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Manage FAQ"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='inviteuser'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/inviteuser" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ShareIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Share Message Manage"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='newuserwelcome'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/newuserwelcome" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<PeopleIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"New User Welcome Popup"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='manageterms'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/manageterms" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<GavelIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Manage Terms"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='SMSBlast'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/SMSBlast" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<NewspaperIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Send SMS Blast"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='AllSentSMS'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/AllSentSMS" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<NewspaperIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"All Send SMS"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='newsletter'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/newsletter" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<NewspaperIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Send Newsletter"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allnewsletter'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/allnewsletter" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<NewspaperIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"All Send Newsletter"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='newuseremail'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/newuseremail" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<NewspaperIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"New User Email Drip"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='publishnews'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/publishnews" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<AnnouncementIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Publish News"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allnews'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/allnews" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<AnnouncementIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"All News"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='alltutorial'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/alltutorial") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CastForEducationIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Quick Start Guide"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addtutorial'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addtutorial" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CastForEducationIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Quick Start Guide"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='alltraining'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/alltraining") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Trainings"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addtraining'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addtraining" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Trainings"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allpost'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allpost") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PostAddIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Social Media Posts"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addpost'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addpost" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PostAddIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Social Media Posts"} />
						</ListItem>
					</Link>
				</>
			)}
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;
	const [user, loading] = GetAuth();

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [open, setOpen] = React.useState(!user?.emailVerified === true);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [openNotice, setOpenNotice] = React.useState();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [unRead, setunread] = React.useState([]);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [openChat, setOpenChat] = React.useState(false);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/chats`)
			.then((res) => res.json())
			.then((data) => {
				setunread(
					data?.filter((e) =>
						e?.chats?.find((n) => !n?.adminSeenTime && n?.adminSeen === false),
					),
				);
			});
	}, [user?.url, open, location, openChat]);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useEffect(() => {
		setOpenNotice(data && data.checkedNotification === false);
	}, [data]);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	/* const [sendEmailVerification, sending] = useSendEmailVerification(auth); */

	if (!loading && user && data && user?.email !== data?.email) {
		return <Navigate to={"/login"} state={{ from: location }} replace />;
	}
	if (loading || !user || !data) {
		return (
			<div
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<CardMedia
					image='/loading.png'
					alt='loading logo'
					className='loading-logo'
					sx={{
						width: 170,
						height: 170,
					}}
				/>
			</div>
		);
	}
	if (data && data?.status === "Inactive") {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					minHeight: "100vh",
				}}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h3'
					component='div'>
					OPPS!
				</Typography>
				<br />
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h5'
					component='div'>
					Contact Admin to get access of the card
				</Typography>
			</div>
		);
	}

	return (
		<Box sx={{ display: "flex" }}>
			<>
				<CssBaseline />
				<AppBar
					position='fixed'
					className='bgColor'
					sx={{
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						padding: 0,
						ml: { sm: `${drawerWidth}px` },
					}}>
					<Toolbar>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							sx={{ mr: 1, display: { sm: "none" } }}>
							<MenuIcon />
						</IconButton>
						<Box
							display='flex'
							sx={{ flexGrow: 1, alignItems: "center", color: "white" }}>
							<Typography variant='body'>
								Digital Networking cards
							</Typography>
						</Box>
						{data?.role === "Admin" && (
							<Badge
								onClick={() => setOpenChat(true)}
								badgeContent={unRead?.length || "0"}
								color='error'
								sx={{ mr: 3, cursor: "pointer", color: "white" }}>
								<MailIcon />
							</Badge>
						)}
						<Button
							onClick={() => signOut(auth)}
							color='inherit'
							className='logout'>
							LogOut
						</Button>
					</Toolbar>
				</AppBar>
				<Box
					/* onClick={() => setMobileOpen(false)} */
					component='nav'
					sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
					aria-label='mailbox folders'>
					<Drawer
						container={container}
						variant='temporary'
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true,
						}}
						className='dashboard'
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
							backgroundColor: "transparent",
						}}>
						{drawer}
					</Drawer>
					<Drawer
						variant='permanent'
						className='dashboard'
						sx={{
							display: { xs: "none", sm: "block" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
							backgroundColor: "transparent",
						}}
						open>
						{drawer}
					</Drawer>
				</Box>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}>
					<Toolbar />
					{openNotice && (
						<NewUserNotice
							openNotice={openNotice}
							data={data}
							setOpenNotice={setOpenNotice}
							setOpen={setOpen}
						/>
					)}
					{/* {open && (
						<Alert
							sx={{
								width: "max-content",
								textAlign: "left",
								position: "fixed",
								top: " 15%",
								right: "50%",
								transform: "translate(50%,-15%)",
								zIndex: 9,
							}}
							action={
								<>
									<button
										style={{
											backgroundColor: "#F27471",
											padding: "7px",
											color: "white",
											borderRadius: "5px",
										}}
										size='small'
										onClick={() => sendEmailVerification()}>
										Resend
									</button>
									<button
										style={{
											backgroundColor: "#F27471",
											padding: "7px",
											color: "white",
											borderRadius: "5px",
										}}
										size='small'
										onClick={() => {
											setOpen(false);
										}}>
										X
									</button>
								</>
							}
							severity='error'>
							<AlertTitle>Warning</AlertTitle>
							<strong>Please verify your email</strong> — check inbox/spam
							folder!
						</Alert>
					)} */}
					{/* 	<Backdrop
						sx={{
							color: "#fff",
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
						open={sending}>
						<CircularProgress color='inherit' />
					</Backdrop> */}
					<Outlet></Outlet>
				</Box>
			</>
			{openChat && (
				<UnreadChat open={openChat} setOpen={setOpenChat} unRead={unRead} />
			)}
		</Box>
	);
}

Dashboard.propTypes = {
	window: PropTypes.func,
};

export default Dashboard;
