import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
	apiKey: "AIzaSyAfH7sdDBfitoSSda8sXYqawuT5FP8CqHg",
	authDomain: "tranzact-card.firebaseapp.com",
	projectId: "tranzact-card",
	storageBucket: "tranzact-card.appspot.com",
	messagingSenderId: "598669798055",
	appId: "1:598669798055:web:b284a5055f56e3367ec982",
	measurementId: "G-5LEGG1RKGT",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
