import React, { useState } from "react";
import GetAuth from "../../GetAuth";

const useDataByEmail = () => {
	const [data, setData] = useState();
	const [user] = GetAuth();
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`)
			.then((res) => res.json())
			.then((data) => setData(data))
			.catch((error) => {
				setData({ email: "Not Found" });
			});
	}, [user?.email]);
	return [data];
};

export default useDataByEmail;
