import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AllMails from "./Pages/Dashboard/AllMails/AllMails";
import AllReviews from "./Pages/Dashboard/AllReviews/AllReviews";
import BannerPhoto from "./Pages/Dashboard/BannerPhoto/BannerPhoto";
import ButtonsLink from "./Pages/Dashboard/ButtonsLink/ButtonsLink";
import ColorChange from "./Pages/Dashboard/ColorChange/ColorChange";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Details from "./Pages/Dashboard/Details/Details";
import Galleryphoto from "./Pages/Dashboard/Galleryphoto/Galleryphoto";
import Headline from "./Pages/Dashboard/Headline/Headline";
import ProfilePhoto from "./Pages/Dashboard/ProfilePhoto/ProfilePhoto";
import HomepageMain from "./Pages/Homepage/HomepageMain";
import Login from "./Pages/Login/Login";
import useData from "./useData";
import AllUsers from "./Pages/Dashboard/AdminSection/AllUsers/AllUsers";
import NotFound from "./Pages/NotFound/NotFound";
import InviteUser from "./Pages/Dashboard/AdminSection/InviteUser/InviteUser";
import NewUserEmail from "./Pages/Dashboard/NewUserEmail/NewUserEmail";
import BlockedScheduleDate from "./Pages/Dashboard/AdminSection/BlockedScheduleDate/BlockedScheduleDate";
import SocialMediaPost from "./Pages/Dashboard/AdminSection/SocialMediaPost/SocialMediaPost";
import Meeting from "./Pages/Meeting/Meeting";
import AllMeetings from "./Pages/Dashboard/AdminSection/AllMeetings/AllMeetings";
import SuccessMeeting from "./Pages/Dashboard/AdminSection/AllMeetings/SuccessMeeting";
import RequestTrainingCall from "./Pages/Dashboard/RequestTrainingCall/RequestTrainingCall";
import RequestTrainingCallList from "./Pages/Dashboard/RequestTrainingCall/RequestTrainingCallList";
import Bg from "./Pages/Dashboard/AdminSection/Bg/Bg";
import AllNews from "./Pages/Dashboard/AdminSection/PublishNews/AllNews";
import UpdateNews from "./Pages/Dashboard/AdminSection/PublishNews/UpdateNews";
import AllTraining from "./Pages/Dashboard/AdminSection/PublishTraining/AllTraining";
import UpdateTraining from "./Pages/Dashboard/AdminSection/PublishTraining/UpdateTraining";
import AllSocialMediaPost from "./Pages/Dashboard/AdminSection/SocialMediaPost/AllSocialMediaPost";
import ViewSocialMediaPost from "./Pages/Dashboard/ViewSocialMediaPost/ViewSocialMediaPost";
import UpdateSocialMediaPost from "./Pages/Dashboard/AdminSection/SocialMediaPost/UpdateSocialMediaPost";
import ScheduleOrJoinDetails from "./Pages/Dashboard/AdminSection/ScheduleOrJoinDetails/ScheduleOrJoinDetails";
import Charts from "./Pages/Dashboard/Charts/Charts";
import MemoryJoggerList from "./Pages/Dashboard/MemoryJoggerList/MemoryJoggerList";
import Test from "./Test";
import RequireAuth from "./RequireAuth/RequireAuth";
import { CardMedia, Typography } from "@mui/material";
import TeamInvite from "./Pages/TeamInvite/TeamInvite";
import NewsLetter from "./Pages/Dashboard/AdminSection/NewsLetter/NewsLetter";
import ManageFAQ from "./Pages/Dashboard/AdminSection/ManageFAQ/ManageFAQ";
import FAQ from "./Pages/FAQ/FAQ";
import AddVideo from "./Pages/Dashboard/AddVideo/AddVideo";
import AllSentNewsletter from "./Pages/Dashboard/AdminSection/AllSentNewsletter/AllSentNewsletter";
import TermManage from "./Pages/Dashboard/AdminSection/TermManage/TermManage";
import MyProfile from "./Pages/Dashboard/AdminSection/AllUsers/MyProfile";
import PublishNews from "./Pages/Dashboard/AdminSection/PublishNews/PublishNews";
import ViewNews from "./Pages/Dashboard/ViewNews/ViewNews";
import { APIContextProvider } from "./apiContext";
import PublishTraining from "./Pages/Dashboard/AdminSection/PublishTraining/PublishTraining";
import ViewTraining from "./Pages/Dashboard/ViewTraining/ViewTraining";
import NewUserWelcome from "./Pages/Dashboard/AdminSection/NewUserWelcome/NewUserWelcome";
import RequireAuthAdmin from "./RequireAuth/RequireAuthAdmin";
import RefferelManage from "./Pages/Dashboard/RefferelManage/RefferelManage";
import SMSBlast from "./Pages/Dashboard/AdminSection/SMSBlast/SMSBlast";
import AllSentSMS from "./Pages/Dashboard/AdminSection/AllSentSMS/AllSentSMS";
import PublishTutorial from "./Pages/Dashboard/AdminSection/PublishTutorial/PublishTutorial";
import AllTutorial from "./Pages/Dashboard/AdminSection/PublishTutorial/AllTutorial";
import UpdateTutorial from "./Pages/Dashboard/AdminSection/PublishTutorial/UpdateTutorial";
import ViewTutorial from "./Pages/Dashboard/ViewTutorial/ViewTutorial";
import Planner from "./Pages/Dashboard/Planner/Planner";
import Slides from "./Pages/Dashboard/Slides/Slides";
import Book from "./Pages/Dashboard/Book/Book";
import AllClicks from "./Pages/Dashboard/AllClicks/AllClicks";

function App() {
	const [data, info] = useData();
	const url = window.location.pathname;
	return (
		<div className='App' style={{ backgroundImage: `url(/bg-img.jpg)` }}>
			{data ? (
				<>
					{document.documentElement.style.setProperty(
						"--color1st",
						`${info?.color1st}`,
					)}
					{data?._id ? (
						<>
							{data?.status === "Active" ? (
								<APIContextProvider>
									<BrowserRouter>
										<Routes>
											<Route path='*' element={<NotFound />} />
											<Route path='/test' element={<Test />} />
											{url === "/" && <Route path='/' element={<Login />} />}
											{url.includes("/@") && (
												<Route
													path={`/@${data?.url}`}
													element={<HomepageMain url={`/@${data?.url}`} />}
												/>
											)}

											{!url.includes("/@") ? (
												<Route path='/meeting' element={<Meeting />} />
											) : (
												<Route
													path={`/@${data?.url}/meeting`}
													element={<Meeting />}
												/>
											)}

											{!url.includes("/@") ? (
												<Route
													path='/jointeam'
													element={
														<RequireAuth>
															<TeamInvite />
														</RequireAuth>
													}
												/>
											) : (
												<Route
													path={`/@${data?.url}/jointeam`}
													element={
														<RequireAuth>
															<TeamInvite />
														</RequireAuth>
													}
												/>
											)}

											{!url.includes("/@") ? (
												<Route path='/login' element={<Login />} />
											) : (
												<Route
													path={`/@${data?.url}/login`}
													element={<Login />}
												/>
											)}
											<Route
												exact
												path='/dashboard'
												element={
													<RequireAuth userInfo={data}>
														<Dashboard />
													</RequireAuth>
												}>
												<Route path='/dashboard' element={<MyProfile />} />
												<Route
													path='/dashboard/bannerphoto'
													element={
														<RequireAuthAdmin>
															<BannerPhoto />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/RefferelManage'
													element={<RefferelManage />}
												/>
												<Route
													path='/dashboard/SMSBlast'
													element={<SMSBlast />}
												/>
												<Route
													path='/dashboard/AllSentSMS'
													element={<AllSentSMS />}
												/>
												<Route
													path='/dashboard/profilephoto'
													element={<ProfilePhoto />}
												/>
												<Route
													path='/dashboard/newuseremail'
													element={<NewUserEmail />}
												/>
												<Route
													path='/dashboard/BlockedScheduleDate'
													element={<BlockedScheduleDate />}
												/>
												<Route
													path='/dashboard/meetings'
													element={<AllMeetings />}
												/>
												<Route
													path='/dashboard/RequestTrainingCall'
													element={<RequestTrainingCall />}
												/>
												<Route
													path='/dashboard/RequestTrainingCallList'
													element={<RequestTrainingCallList />}
												/>
												<Route
													path='/dashboard/meetings'
													element={<AllMeetings />}
												/>
												<Route path='/dashboard/Slides' element={<Slides />} />
												<Route path='/dashboard/Book' element={<Book />} />
												<Route
													path='/dashboard/meetingSucceed'
													element={<SuccessMeeting />}
												/>
												<Route
													path='/dashboard/allusers'
													element={
														<RequireAuthAdmin>
															<AllUsers />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/allusers/:unReadChat'
													element={
														<RequireAuthAdmin>
															<AllUsers />
														</RequireAuthAdmin>
													}
												/>
												<Route path='/dashboard/faq' element={<FAQ />} />{" "}
												<Route
													path='/dashboard/AllClicks'
													element={<AllClicks />}
												/>{" "}
												<Route
													path='/dashboard/inviteuser'
													element={<InviteUser />}
												/>
												<Route
													path='/dashboard/headline'
													element={<Headline />}
												/>
												<Route
													path='/dashboard/allnewsletter'
													element={
														<RequireAuthAdmin>
															<AllSentNewsletter />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/ScheduleOrJoinDetails'
													element={
														<RequireAuthAdmin>
															<ScheduleOrJoinDetails />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/buttonslink'
													element={<ButtonsLink />}
												/>
												<Route
													path='/dashboard/colorchange'
													element={
														<RequireAuthAdmin>
															<ColorChange />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/details'
													element={<Details />}
												/>
												<Route
													path='/dashboard/viewnews'
													element={<ViewNews />}
												/>
												<Route
													path='/dashboard/publishnews'
													element={
														<RequireAuthAdmin>
															<PublishNews />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/newsletter'
													element={
														<RequireAuthAdmin>
															<NewsLetter />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/galleryphoto'
													element={
														<RequireAuthAdmin>
															<Galleryphoto />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/newuserwelcome'
													element={
														<RequireAuthAdmin>
															<NewUserWelcome />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/bg'
													element={
														<RequireAuthAdmin>
															<Bg />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/managefaq'
													element={
														<RequireAuthAdmin>
															<ManageFAQ />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/allreviews'
													element={<AllReviews />}
												/>
												<Route
													path='/dashboard/MemoryJoggerList'
													element={<MemoryJoggerList />}
												/>
												<Route
													path='/dashboard/allemails'
													element={<AllMails />}
												/>
												<Route
													path='/dashboard/manageterms'
													element={
														<RequireAuthAdmin>
															<TermManage />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allnews"}
													element={
														<RequireAuthAdmin>
															<AllNews />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allnews/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateNews />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addtutorial"}
													element={
														<RequireAuthAdmin>
															<PublishTutorial />
														</RequireAuthAdmin>
													}
												/>{" "}
												<Route
													path={"/dashboard/Planner"}
													element={<Planner />}
												/>
												<Route
													path={"/dashboard/alltutorial"}
													element={
														<RequireAuthAdmin>
															<AllTutorial />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/tutorials"}
													element={<ViewTutorial />}
												/>
												<Route
													path={"/dashboard/alltutorial/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateTutorial />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addtraining"}
													element={
														<RequireAuthAdmin>
															<PublishTraining />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/alltraining"}
													element={
														<RequireAuthAdmin>
															<AllTraining />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/trainings"}
													element={<ViewTraining />}
												/>
												<Route
													path={"/dashboard/alltraining/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateTraining />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/Charts"}
													element={<Charts />}
												/>
												<Route
													path={"/dashboard/addpost"}
													element={
														<RequireAuthAdmin>
															<SocialMediaPost />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allpost"}
													element={
														<RequireAuthAdmin>
															<AllSocialMediaPost />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allposts"}
													element={<ViewSocialMediaPost />}
												/>
												<Route
													path={"/dashboard/allpost/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateSocialMediaPost />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/managevideo'
													element={
														<RequireAuthAdmin>
															<AddVideo />
														</RequireAuthAdmin>
													}
												/>
											</Route>
										</Routes>
									</BrowserRouter>
								</APIContextProvider>
							) : (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
										minHeight: "100vh",
									}}>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h3'
										component='div'>
										OPPS!
									</Typography>
									<br />
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h5'
										component='div'>
										This card is not available at the moment.
									</Typography>
								</div>
							)}
						</>
					) : (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								minHeight: "100vh",
							}}>
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h3'
								component='div'>
								OPPS!
							</Typography>
							<br />
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h5'
								component='div'>
								{data}
							</Typography>
						</div>
					)}
				</>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CardMedia
						image='/loading.png'
						alt='loading logo'
						className='loading-logo'
						sx={{
							width: 170,
							height: 170,
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default App;
