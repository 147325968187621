import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	FormControlLabel,
	FormGroup,
	Switch,
	alpha,
	styled,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { green } from "@mui/material/colors";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const NewUserEmail = () => {
	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	const form = useRef();
	const [emails, setEmails] = useState();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			dayOne: "",
			dayTwo: "",
			dayThree: "",
			dayFour: "",
			dayFive: "",
			daySix: "",
			daySeven: "",
			dayOneSubject: "",
			dayTwoSubject: "",
			dayThreeSubject: "",
			dayFourSubject: "",
			dayFiveSubject: "",
			daySixSubject: "",
			daySevenSubject: "",
		},
	});

	const [checked, setChecked] = React.useState();
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_PATH}/newuseremails`).then((res) => {
			reset(res.data?.emails);
			setEmails(res.data);
			setChecked(res.data?.status === "on" ? true : false);
		});
	}, [reset, submitting]);
	const onSubmit = ({
		dayOne,
		dayTwo,
		dayThree,
		dayFour,
		dayFive,
		daySix,
		daySeven,
		dayOneSubject,
		dayTwoSubject,
		dayThreeSubject,
		dayFourSubject,
		dayFiveSubject,
		daySixSubject,
		daySevenSubject,
	}) => {
		setSubmitting(true);
		const data = {
			dayOne,
			dayTwo,
			dayThree,
			dayFour,
			dayFive,
			daySix,
			daySeven,
			dayOneSubject,
			dayTwoSubject,
			dayThreeSubject,
			dayFourSubject,
			dayFiveSubject,
			daySixSubject,
			daySevenSubject,
			type: "NewUserEmail",
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		axios
			.put(
				`${process.env.REACT_APP_API_PATH}/newuseremails/${emails?._id}`,
				data,
			)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your details updated Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleChange2 = (event) => {
		Swal.fire({
			title: "Are you sure?",
			text:
				"You want to turn it " + (!event.target.checked ? "off" : "on") + "!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#3085d6",
			confirmButtonText:
				"Yes, turn it " + (!event.target.checked ? "off" : "on") + "!",
		}).then((result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				axios
					.put(
						`${process.env.REACT_APP_API_PATH}/emailOnOff/${emails?._id}/${
							!event.target.checked ? "on" : "off"
						}`,
					)
					.then(function (response) {
						setSubmitting(false);
						Swal.fire({
							icon: "success",
							title: "Your details updated Successfully",
							showConfirmButton: false,
							timer: 1500,
						});
						setChecked(event.target.checked);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const GreenSwitch = styled(Switch)(({ theme }) => ({
		"& .MuiSwitch-switchBase.Mui-checked": {
			color: green[600],
			"&:hover": {
				backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
			},
		},
		"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
			backgroundColor: green[600],
		},
	}));
	return (
		<Container
			sx={{
				pt: 1,
				"& .MuiSwitch-switchBase": {
					backgroundColor: "transparent !important",
				},
			}}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				New user auto email for seven days
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {emails?.emails?.submitTime || "N/A"}
			</Typography>
			<FormGroup
				sx={{
					marginBottom: "20px",
				}}>
				<FormControlLabel
					required
					control={
						<GreenSwitch
							checked={checked}
							onChange={handleChange2}
							inputProps={{ "aria-label": "Email on off" }}
							size='large'
						/>
					}
					label={`Email ${checked ? "on" : "off"}`}
				/>
			</FormGroup>

			<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor='secondary'
					textColor='inherit'
					variant='fullWidth'
					aria-label='full width tabs example'>
					<Tab label='One' {...a11yProps(0)} />
					<Tab label='Two' {...a11yProps(1)} />
					<Tab label='Three' {...a11yProps(2)} />
					<Tab label='Four' {...a11yProps(3)} />
					<Tab label='Five' {...a11yProps(4)} />
					<Tab label='Six' {...a11yProps(5)} />
					<Tab label='Seven' {...a11yProps(6)} />
				</Tabs>
				<SwipeableViews
					axis={theme.direction === "rtl" ? "x-reverse" : "x"}
					index={value}
					onChangeIndex={handleChangeIndex}>
					<TabPanel value={value} index={0} dir={theme.direction}>
						<TextField
							required
							name='dayOneSubject'
							label='Day One Subject'
							fullWidth
							sx={{ mb: 2 }}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayOneSubject", { required: true })}
						/>
						<TextField
							required
							name='dayOne'
							label='Day One'
							fullWidth
							multiline
							rows={11}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayOne", { required: true })}
						/>
					</TabPanel>
					<TabPanel value={value} index={1} dir={theme.direction}>
						<TextField
							required
							name='dayTwoSubject'
							label='Day Two Subject'
							sx={{ mb: 2 }}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayTwoSubject", { required: true })}
						/>
						<TextField
							required
							name='dayTwo'
							label='Day Two'
							fullWidth
							multiline
							rows={11}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayTwo", { required: true })}
						/>
					</TabPanel>
					<TabPanel value={value} index={2} dir={theme.direction}>
						<TextField
							required
							name='dayThreeSubject'
							label='Day Three Subject'
							sx={{ mb: 2 }}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayThreeSubject", { required: true })}
						/>
						<TextField
							required
							name='dayThree'
							label='Day Three'
							fullWidth
							multiline
							rows={11}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayThree", { required: true })}
						/>
					</TabPanel>
					<TabPanel value={value} index={3} dir={theme.direction}>
						<TextField
							required
							name='dayFourSubject'
							label='Day Four Subject'
							sx={{ mb: 2 }}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayFourSubject", { required: true })}
						/>
						<TextField
							required
							name='dayFour'
							label='Day Four'
							fullWidth
							multiline
							rows={11}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayFour", { required: true })}
						/>
					</TabPanel>
					<TabPanel value={value} index={4} dir={theme.direction}>
						<TextField
							required
							name='dayFiveSubject'
							label='Day Five Subject'
							sx={{ mb: 2 }}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayFiveSubject", { required: true })}
						/>
						<TextField
							required
							name='dayFive'
							label='Day Five'
							fullWidth
							multiline
							rows={11}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("dayFive", { required: true })}
						/>
					</TabPanel>
					<TabPanel value={value} index={5} dir={theme.direction}>
						<TextField
							required
							name='daySixSubject'
							label='Day Six Subject'
							sx={{ mb: 2 }}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("daySixSubject", { required: true })}
						/>
						<TextField
							required
							name='daySix'
							label='Day Six'
							fullWidth
							multiline
							rows={11}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("daySix", { required: true })}
						/>
					</TabPanel>
					<TabPanel value={value} index={6} dir={theme.direction}>
						<TextField
							required
							name='daySevenSubject'
							label='Day Seven Subject'
							sx={{ mb: 2 }}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("daySevenSubject", { required: true })}
						/>
						<TextField
							required
							name='daySeven'
							label='Day Seven'
							fullWidth
							multiline
							rows={11}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("daySeven", { required: true })}
						/>
					</TabPanel>
				</SwipeableViews>
				<Button
					type='submit'
					variant='contained'
					sx={{
						width: "100%",
						mb: 2,
						px: 3,
						fontWeight: "bold",
						borderRadius: "25px",
					}}>
					Update
				</Button>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !emails}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default NewUserEmail;
