import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Fade,
	Modal,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import axios from "axios";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import Note from "./Note";
import StatusChange from "./StatusChange";
import { DataGrid } from "@mui/x-data-grid";
import Search from "../../../Pagination/Search";
import Pagination from "../../../Pagination/Pagination";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { md: "40vw", sm: "60vw", xs: "95vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	bgcolor: "background.paper",
	border: "2px solid",
	boxShadow: 24,
	p: 4,
};

const RequestTrainingCallList = () => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [openNote, setOpenNote] = useState(false);
	const [users, setUsers] = useState();
	const [user, setUser] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");

	const [filtered, setFiltered] = useState("");
	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_PATH}/RequestTrainingCall?page=${currentPage}&perPage=${itemsPerPage}&filtered=${filtered}${searchQuery}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data?.data);
				setTotalItems(data.pagination.total);
			});
	}, [currentPage, itemsPerPage, searchQuery, submitting, filtered]);

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/RequestTrainingCall/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That request has been deleted.", "success");
						setSubmitting(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const columns = [
		{
			field: "id",
			headerName: "ID",
			minWidth: 150,
		},
		{
			field: "submitTime",
			headerName: "Submited On",
			minWidth: 230,
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 150,
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 200,
		},
		{
			field: "phone",
			headerName: "Phone",
			minWidth: 120,
			valueGetter: (params) => params?.row?.phone,
			renderCell: (params) => {
				return (
					<a
						href={`tel:${params?.row?.phone}`}
						className='textColor'
						style={{ textDecoration: "none" }}>
						{params?.row?.phone}
					</a>
				);
			},
		},
		{
			field: "userName",
			headerName: "UserName",
			minWidth: 120,
		},
		{
			field: "Status",
			headerName: "Status",
			minWidth: 100,
			filterable: false,
			renderCell: (params) => {
				return (
					<StatusChange user={params?.row} setSubmitting={setSubmitting} />
				);
			},
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 270,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }}>
						<Button
							onClick={() => {
								handleOpen();
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							onClick={() => {
								setOpenNote(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => handleDelete(params?.row?._id)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</Box>
				);
			},
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Box sx={{ mx: 2 }}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h4'>
					All Call Requests
				</Typography>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
				<Button
					className={filtered === "" && "activeBtnPayment"}
					onClick={() => setFiltered("")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					All
				</Button>
				<Button
					className={filtered === "Pending" && "activeBtnPayment"}
					onClick={() => setFiltered("Pending")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					Pending
				</Button>
				<Button
					className={filtered === "Closed" && "activeBtnPayment"}
					onClick={() => setFiltered("Closed")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					Closed
				</Button>
			</Box>
			{users && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={users}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: () => (
								<Search
									setSearchQuery={setSearchQuery}
									searchFields={[
										"id",
										"name",
										"phone",
										"email",
										"status",
										"details",
										"submitTime",
										"userName",
										"closedOn",
									]}
								/>
							),
							Footer: () => (
								<Pagination
									totalItems={totalItems}
									currentPage={currentPage}
									itemsPerPage={itemsPerPage}
									onPageChange={handlePageChange}
								/>
							),
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} user={user} />
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<Box sx={style}>
						<Typography className='textColor' variant='h6' component='h2'>
							Why want to Request Training Call?
						</Typography>
						<Typography className='textColor' sx={{ mt: 2 }}>
							{user?.details}
						</Typography>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default RequestTrainingCallList;
