import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	FormControl,
	NativeSelect,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const InviteUser = () => {
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const [data, setData] = useState();
	console.log("🚀 ~ InviteUser ~ data:", data)
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_PATH}/shareTexts`).then((res) => {
			setData(res.data);
		});
	}, [submitting]);
	const onSubmit = ({ select, message }) => {
		setSubmitting(true);
		axios
			.post(`${process.env.REACT_APP_API_PATH}/shareTexts`, {
				select,
				message,
				submitTime: new Date().toLocaleString("en-US", {
					timeZone: "America/Los_Angeles",
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
					second: "2-digit",
					timeZoneName: "short",
				}),
			})
			.then(function (response) {
				setSubmitting(false);
				reset();
				Swal.fire({
					icon: "success",
					title: "Successfully Added",
					showConfirmButton: true,
					timer: 2500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/shareTexts/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "Successfully deleted.", "success");
						setSubmitting(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const columns = [
		{
			field: "select",
			headerName: "Subject",
			minWidth: 150,
		},
		{
			field: "message",
			headerName: "Message",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 50,
			filterable: false,
			renderCell: (params) => {
				return (
					<Button
						className='button border'
						onClick={() => handleDelete(params?.row?._id)}
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'>
						<DeleteIcon />
					</Button>
				);
			},
		},
	];
	const [open, setOpen] = useState(false);
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold", fontSize: "1.8rem" }}>
				Manage Share Messages
			</Typography>
			<Button
				onClick={() => (open ? setOpen(false) : setOpen(true))}
				variant='contained'
				type='submit'
				sx={{
					mb: 1,
					px: 3,
					fontWeight: "bold",
					borderRadius: "25px",
					backgroundColor: open && "red !important",
				}}>
				{open ? "Close" : "Add New"}
			</Button>
			{open && (
				<Grid container spacing={3} sx={{ justifyContent: "center" }}>
					<Grid item md={6} sm={6} xs={12}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormControl fullWidth>
								<NativeSelect
									sx={{ my: 2 }}
									defaultValue={"General-Invite"}
									{...register("select", { required: true })}>
									<option value={"General-Invite"}>General Invite</option>
									<option value={"Entrepreneur-Invite"}>
										Entrepreneur Invite
									</option>
									<option value={"FM-Invite"}>FM Invite</option>
									<option value={"FBO-Invite"}>FBO Invite</option>
								</NativeSelect>
							</FormControl>
							<TextField
								{...register("message", { required: true })}
								required
								fullWidth
								multiline
								rows={7}
								label='Messages'
							/>
							<Button
								variant='contained'
								type='submit'
								sx={{
									width: "100%",
									my: 2,
									px: 3,
									fontWeight: "bold",
									borderRadius: "25px",
								}}>
								Add Message
							</Button>
						</form>
					</Grid>
				</Grid>
			)}
			{data && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={data}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !data}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default InviteUser;
