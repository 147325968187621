import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";

const NewsLetter = () => {
	const { register, handleSubmit, reset } = useForm();

	const [submitting, setSubmitting] = useState(false);
	const onSubmit = ({ subject }) => {
		const data = {
			email: convertedContent,
			subject,
			time: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		setSubmitting(true);
		axios
			.post(`${process.env.REACT_APP_API_PATH}/sendMail`, data)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Your Messsage Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
				reset();
				setSubmitting(false);
			})
			.catch(function (error) {
				console.log("error", error);
				console.log(error);
			});
	};

	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty(),
	);
	const [convertedContent, setConvertedContent] = useState(null);
	const handleEditorChange = (state) => {
		setEditorState(state);
		convertContentToHTML();
	};

	const convertContentToHTML = () => {
		let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
		setConvertedContent(currentContentAsHTML);
	};
	return (
		<Box sx={{ px: 2, mt: 2, minHeight: "100vh" }}>
			<Typography
				className='textColor'
				sx={{ mb: 1, fontWeight: "bold" }}
				variant='h4'
				component='div'
				gutterBottom>
				Send Newsletter To Everyone
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							sx={{ width: "100%" }}
							id='outlined-basic'
							name='Subject'
							label='Subject'
							{...register("subject", { required: true })}
						/>
					</Grid>
					{/* 	<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							sx={{ width: "100%" }}
							id='outlined-basic'
							name='CC'
							label='CC'
							{...register("cc")}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							sx={{ width: "100%" }}
							id='outlined-basic'
							name='BCC'
							label='BCC'
							{...register("bcc")}
						/>
					</Grid> */}
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<Editor
							editorState={editorState}
							onEditorStateChange={handleEditorChange}
							wrapperClassName='wrapper'
							editorClassName='editor'
							toolbarClassName='toolbar'
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<Button
							className='sendButton'
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Send
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default NewsLetter;
