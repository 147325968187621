import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "30vw", sm: "60vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const ViewOthersProfile = ({ user, open, setOpen }) => {
	const { register, reset } = useForm({
		defaultValues: {
			addressLine1: "",
			state: "",
			city: "",
			zip: "",
			country: "",
		},
	});
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`)
			.then((res) => {
				reset(res.data?.address);
			});
	}, [user, reset]);

	return (
		<div>
			{user ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Typography
								variant='h4'
								gutterBottom
								className='textColor'
								sx={{ fontWeight: "bold" }}>
								{user?.headline?.title}'s Profile
							</Typography>
							<form
								method='post'
								style={{ display: "flex", flexDirection: "column" }}>
								<Grid container>
									<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
										<TextField
											disabled
											InputLabelProps={{
												shrink: true,
											}}
											fullWidth
											size='small'
											id='email'
											label='Email Address'
											name='email'
											autoComplete='email'
											autoFocus
											value={user?.email}
											sx={{ maxWidth: "100%" }}
										/>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
										<TextField
											disabled
											InputLabelProps={{
												shrink: true,
											}}
											fullWidth
											size='small'
											id='phone'
											label='Your Phone Number'
											name='phone'
											autoComplete='phone'
											autoFocus
											sx={{ maxWidth: "100%" }}
											defaultValue={user?.phone}
											{...register("phone")}
										/>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
										<TextField
											disabled
											InputLabelProps={{
												shrink: true,
											}}
											fullWidth
											size='small'
											sx={{ width: "100%" }}
											id='outlined-basic'
											type={"text"}
											name='Address Line 1'
											label='Address Line 1'
											{...register("addressLine1")}
										/>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
										<Grid container spacing={1.5}>
											<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
												<TextField
													sx={{ width: "100%" }}
													disabled
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
													size='small'
													id='outlined-basic'
													type={"text"}
													name='City'
													label='City'
													{...register("city")}
												/>
											</Grid>
											<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
												<TextField
													disabled
													InputLabelProps={{
														shrink: true,
													}}
													fullWidth
													size='small'
													sx={{ width: "100%" }}
													id='outlined-basic'
													type={"text"}
													name='State/Region'
													label='State/Region'
													{...register("state")}
												/>
											</Grid>
											<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
												<TextField
													InputLabelProps={{
														shrink: true,
													}}
													disabled
													fullWidth
													size='small'
													sx={{ width: "100%" }}
													id='outlined-basic'
													type={"text"}
													name='Zip/Postal Code'
													label='Zip/Postal Code'
													{...register("zip")}
												/>
											</Grid>
											<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
												<TextField
													InputLabelProps={{
														shrink: true,
													}}
													disabled
													fullWidth
													size='small'
													sx={{ width: "100%" }}
													id='outlined-basic'
													type={"text"}
													name='Country'
													label='Country'
													{...register("country")}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto", mt: 1 }}>
										<TextField
											InputLabelProps={{
												shrink: true,
											}}
											disabled
											fullWidth
											size='small'
											sx={{ width: "100%" }}
											id='outlined-basic'
											type={"text"}
											name='Username'
											label='Username'
											value={user?.url}
										/>
									</Grid>
								</Grid>
							</form>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!user}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default ViewOthersProfile;
