import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import GetAuth from "../../../GetAuth";

const RefferelManage = ({ anotherUser }) => {
	const [id, setId] = useState();
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			profileID: "",
		},
	});
	const [info, setInfo] = useState();
	const [user] = GetAuth();
	useEffect(() => {
		axios
			.get(
				anotherUser
					? `${process.env.REACT_APP_API_PATH}/userbyemail/${anotherUser?.email}`
					: `${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`,
			)
			.then((res) => {
				reset(res.data?.refferel);
				setId(res.data?._id);
				setInfo(res.data?.refferel);
			});
	}, [reset, user?.email, anotherUser]);
	const onSubmit = ({ profileID }) => {
		setSubmitting(true);
		const data = {
			type: "refferel",
			profileID,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		axios
			.put(`${process.env.REACT_APP_API_PATH}/refferel/${id}`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Finmore Profile ID
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>

			<form onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={3} justifyContent='center'>
					<Grid item md={7} xs={12}>
						<Grid container spacing={3} justifyContent='center'>
							<Grid item xs={12}>
								<TextField
									required
									name='profileID'
									label='Your Finmore Profile ID'
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
									{...register("profileID", { required: true })}
								/>
							</Grid>

							<Grid item xs={12}>
								<Button
									type='submit'
									variant='contained'
									sx={{
										width: "100%",
										mb: 2,
										px: 3,
										fontWeight: "bold",
										borderRadius: "25px",
									}}>
									Save
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default RefferelManage;
