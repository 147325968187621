import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";

const TermManage = () => {
	const form = useRef();

	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			terms: "",
		},
	});
	const [terms, setTerms] = useState();
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_PATH}/terms`).then((res) => {
			reset(res.data?.terms);
			setTerms(res.data?.terms);
		});
	}, [reset, submitting]);
	const onSubmit = ({ terms }) => {
		setSubmitting(true);
		const data = {
			terms,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		axios
			.put(`${process.env.REACT_APP_API_PATH}/terms`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your terms updated Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Terms and Conditions
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {terms?.submitTime || "N/A"}
			</Typography>

			<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={3}>
					<Grid item md={12} sm={12} xs={12}>
						<TextField
							required
							name='terms'
							label='Terms and conditions'
							fullWidth
							multiline
							rows={11}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("terms", { required: true })}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Update
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !terms}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default TermManage;
