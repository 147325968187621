import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	TextField,
	CircularProgress,
	Backdrop,
	Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAPI } from "../../../../apiContext";
import ScrollToBottom from "react-scroll-to-bottom";
import FaceIcon from "@mui/icons-material/Face";
import CampaignIcon from "@mui/icons-material/Campaign";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "40vw", sm: "60vw", xs: "90vw" },
	height: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const Chat = ({ user, openChat, setOpenChat, socket, room }) => {
	const { databyEmail } = useAPI();
	const [currentMessage, setCurrentMessage] = useState("");
	const [messageList, setMessageList] = useState([]);
	const [count, setCount] = useState();
	useEffect(() => {
		socket.on("activeUser", (data) => {
			setCount(data);
		});
	}, [socket]);
	const [id, setId] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/chatsbyroom/${room}`)
			.then((res) => res.json())
			.then((data) => {
				setId(data?._id);
				setMessageList(data?.chats);
			});
	}, [room]);
	const sendMessage = async () => {
		if (currentMessage !== "") {
			const messageData = {
				id: id,
				room: room,
				sender: databyEmail?.url,
				message: currentMessage,
				submitTime: new Date().toLocaleString("en-US", {
					timeZone: "America/Los_Angeles",
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
					second: "2-digit",
					timeZoneName: "short",
				}),
				msgId: Math.floor(Math.random() * 90000) + 10000,
				adminSeen: false,
				clientSeen: false,
			};
			await socket.emit("send_message", messageData);
			setMessageList((list) => [...list, messageData]);
			setCurrentMessage("");
		}
	};

	useEffect(() => {
		socket.on("receive_message", (data) => {
			setMessageList((list) => [...list, data]);
		});
	}, [socket]);

	const [admin, setAdmin] = useState([]);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/getAdmins`)
			.then((res) => res.json())
			.then((data) => {
				setAdmin(data);
			});
	}, []);
	const [typing, setTyping] = useState();
	useEffect(() => {
		socket.on("typing_receive", (data) => {
			setTyping(data);
		});
	}, [socket]);
	return (
		<Box>
			{databyEmail ? (
				<Modal
					className='chat'
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={openChat}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={openChat}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => {
									setOpenChat(false);
									/* socket.emit("leave_room", room); */
								}}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>{" "}
							<Box sx={{ p: 1 }}>
								<Box
									className='chat-window'
									onClick={() =>
										socket.emit(
											databyEmail?.role === "Admin"
												? "admin_seen_message"
												: "client_seen_message",
											{ id },
										)
									}>
									<Box sx={{ mb: 1 }}>
										<Chip
											icon={<FaceIcon />}
											label={room}
											variant='outlined'
											sx={{ mx: 0.5 }}
										/>
										<Chip
											sx={{ mx: 0.5 }}
											label={count >= 2 ? "Online" : "Offline"}
											color={count >= 2 ? "success" : "error"}
										/>
										<Chip
											onClick={() =>
												socket.emit("alert_send", {
													alert: true,
													room: room === databyEmail?.url ? admin : room,
												})
											}
											icon={
												<CampaignIcon
													sx={{
														color: "white !important",
													}}
												/>
											}
											label='Alert'
											variant='outlined'
											sx={{
												mx: 0.5,
												backgroundColor: "red !important",
												"&:hover": {
													backgroundColor: "red !important",
													color: "white !important",
												},
											}}
										/>
									</Box>
									<Box>
										<ScrollToBottom
											className='chat-body'
											style={{ position: "relative" }}>
											{messageList?.map((messageContent) => {
												return (
													<Box
														className='message'
														id={
															messageContent?.sender === databyEmail?.url
																? "other"
																: "you"
														}>
														{messageContent?.sender !== databyEmail?.url && (
															<FaceIcon
																sx={{
																	border: "1px solid black",
																	borderRadius: "50%",
																	p: 0.1,
																	color: "black",
																}}
															/>
														)}
														<Box>
															<Box
																className='message-content'
																title={messageContent.submitTime}>
																<p>{messageContent.message}</p>
															</Box>
															<Box className='message-meta'>
																<p id='author'>{messageContent.sender}</p>
															</Box>
														</Box>
														{messageContent?.sender === databyEmail?.url && (
															<FaceIcon
																sx={{
																	border: "1px solid #0084FF",
																	borderRadius: "50%",
																	p: 0.1,
																	color: "#0084FF",
																}}
															/>
														)}
													</Box>
												);
											})}
											{typing?.typing === true &&
												typing?.typer !== databyEmail?.url && (
													<Box
														className='chat-bubble'
														sx={{
															position: "absolute",
															bottom: 5,
															right: 5,
														}}>
														<Box className='typing'>
															<div className='dot'></div>
															<div className='dot'></div>
															<div className='dot'></div>
														</Box>
													</Box>
												)}
										</ScrollToBottom>
									</Box>

									<Box className='chat-footer'>
										<Box sx={{ mt: 2 }}>
											<TextField
												required
												name='Message'
												label='Message'
												fullWidth
												multiline
												rows={1}
												InputLabelProps={{
													shrink: true,
												}}
												value={currentMessage}
												placeholder='Hey...'
												onChange={(event) => {
													setCurrentMessage(event.target.value);
												}}
												onKeyPress={(event) => {
													event.key === "Enter" && sendMessage();
												}}
												onInput={() => {
													socket.emit("typing_send", {
														typing: true,
														typer: databyEmail?.url,
														room: room,
													});
												}}
												onBlur={() => {
													socket.emit("typing_send", {
														typing: false,
														typer: databyEmail?.url,
														room: room,
													});
												}}
											/>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "right",
													mt: 1,
												}}>
												<Button
													onClick={sendMessage}
													sx={{
														fontWeight: "bold",
														backgroundColor: "transparent",
														borderRadius: "25px",
													}}
													variant='contained'>
													Send
												</Button>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
				open={!databyEmail || !room}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default Chat;
