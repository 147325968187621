import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import useDataByEmail from "../useDataByEmail";
import SmsIcon from "@mui/icons-material/Sms";
import SendSms from "./SendSms";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Note from "./Note";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "../../../Pagination/Pagination";
import Search from "../../../Pagination/Search";

const MemoryJoggerList = () => {
	const [data] = useDataByEmail();
	const [submitting, setSubmitting] = useState(false);
	const [open, setOpen] = useState(false);
	const [openNote, setOpenNote] = useState(false);
	const [users, setUsers] = useState();
	const [user, setUser] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");

	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	useEffect(() => {
		if (data?.role) {
			fetch(
				`${process.env.REACT_APP_API_PATH}/MemoryJoggerList/${data?.role}/${data?.url}?page=${currentPage}&perPage=${itemsPerPage}${searchQuery}`,
			)
				.then((res) => res.json())
				.then((datas) => {
					setTotalItems(datas.pagination.total);
					setUsers(datas?.data?.reverse());
				});
		}
	}, [data, submitting, open, currentPage, itemsPerPage, searchQuery]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/MemoryJoggerList/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That data has been deleted.", "success");
						setSubmitting(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const [state, setState] = React.useState({
		tcm: false,
		dbo: false,
	});

	const { tcm, dbo } = state;
	const handleChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		});
	};

	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ name, phone }) => {
		setSubmitting(true);
		const datas = {
			name,
			phone,
			tcm,
			dbo,
			submittedBy: data?.url,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		axios
			.post(`${process.env.REACT_APP_API_PATH}/MemoryJoggerList`, datas)
			.then(function (response) {
				setSubmitting(false);
				reset();
				Swal.fire({
					icon: "success",
					title: "Successfully Added",
					showConfirmButton: true,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	/* let count = 1; */

	const columns = [
		{
			field: "name",
			headerName: "Name",
			minWidth: 150,
		},
		{
			field: "phoneNumber",
			headerName: "Phone",
			minWidth: 150,
			valueGetter: (params) => params?.row?.phoneNumber,
			renderCell: (params) => {
				return (
					<a
						href={`tel:${params?.row?.phoneNumber}`}
						className='textColor'
						style={{ textDecoration: "none" }}>
						{params?.row?.phone}
					</a>
				);
			},
		},
		{
			field: "tcm",
			headerName: "FM?",
			minWidth: 140,
			valueGetter: (params) => params?.row?.tcm,
			renderCell: (params) => {
				return params?.row?.tcm === true ? "Yes" : "No";
			},
		},
		{
			field: "dbo",
			headerName: "FBO?",
			minWidth: 140,
			valueGetter: (params) => params?.row?.dbo,
			renderCell: (params) => {
				return params?.row?.dbo === true ? "Yes" : "No";
			},
		},
		{
			field: "submittedBy",
			headerName: "Submitted By",
			minWidth: 150,
		},
		{
			field: "submitTime",
			headerName: "Submit Time",
			minWidth: 240,
		},
		{
			field: "smsSent",
			headerName: "SMS Sent?",
			minWidth: 110,
			valueGetter: (params) => params?.row?.smsSent,
			renderCell: (params) => {
				return params?.row?.smsSent === true ? "Yes" : "No";
			},
		},
		{
			field: "action",
			headerName: "Action",
			minWidth: 250,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }}>
						<Button
							onClick={() => {
								setOpenNote(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => {
								setUser(params?.row);
								setOpen(true);
							}}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<SmsIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => handleDelete(params?.row?._id)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</Box>
				);
			},
		},
	];
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Box
				sx={{
					px: 2,
					height: {
						xs: "auto",
						md: "20vh",
					},
					mb: {
						xs: 2,
						sm: 0,
					},
				}}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					gutterBottom
					variant='h4'>
					My List
				</Typography>
				{/* <Typography className='textColor' gutterBottom>
					Last added on {users?.[0]?.submitTime || "N/A"}
				</Typography> */}
				<form onSubmit={handleSubmit(onSubmit)} method='post'>
					<Grid container spacing={2}>
						<Grid item md={4} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='Name'
								label='Name'
								{...register("name", { required: true })}
							/>
						</Grid>
						<Grid item md={4} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='phone'
								label='Phone Number'
								{...register("phone", { required: true })}
							/>
						</Grid>
						<Grid item md={2} xs={12} sx={{ mx: "auto" }}>
							<FormControlLabel
								value='top'
								control={
									<Checkbox
										sx={{ mr: 0.7 }}
										size='small'
										checked={tcm}
										onChange={handleChange}
										name='tcm'
									/>
								}
								label='FM?'
							/>
							<FormControlLabel
								value='top'
								control={
									<Checkbox
										sx={{ mr: 0.7 }}
										size='small'
										checked={dbo}
										onChange={handleChange}
										name='dbo'
									/>
								}
								label='FBO?'
							/>
						</Grid>
						<Grid item md={2} xs={12} sx={{ mx: "auto" }}>
							<Button
								className='sendButton'
								type='submit'
								variant='contained'
								sx={{
									width: "100%",
									height: "100%",
									px: 3,
									fontWeight: "bold",
									borderRadius: "9px",
								}}>
								<AddIcon sx={{ mr: 1 }} /> Add
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
			{users && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={users}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: () => (
								<Search
									setSearchQuery={setSearchQuery}
									searchFields={["name", "phone", "submittedBy", "submitTime"]}
								/>
							),
							Footer: () => (
								<Pagination
									totalItems={totalItems}
									currentPage={currentPage}
									itemsPerPage={itemsPerPage}
									onPageChange={handlePageChange}
								/>
							),
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			{open && <SendSms open={open} setOpen={setOpen} user={user} />}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users || submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} datas={user} />
			)}
		</Box>
	);
};

export default MemoryJoggerList;
