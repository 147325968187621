import {
	Backdrop,
	Button,
	Chip,
	CircularProgress,
	Grid,
	Paper,
	Typography,
	Fab,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
/* import AttachMoneyIcon from "@mui/icons-material/AttachMoney"; */
import Chat from "./Chat";
import ChatIcon from "@mui/icons-material/Chat";
import AndroidIcon from "@mui/icons-material/Android";
import { useReactPWAInstall } from "react-pwa-install";
import AppleIcon from "@mui/icons-material/Apple";
import BoltIcon from "@mui/icons-material/Bolt";
import ShareIcon from "@mui/icons-material/Share";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PeopleIcon from "@mui/icons-material/People";
import RecrutedByUser from "./RecrutedByUser";
import HowToInstall from "./HowToInstall";
import ShareManage from "./ShareManage";
import { useAPI } from "../../../../apiContext";
import FaceIcon from "@mui/icons-material/Face";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import SmsIcon from "@mui/icons-material/Sms";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EditProfile from "./EditProfile";
import GroupChat from "./GroupChat";
import io from "socket.io-client";
import { reactLocalStorage } from "reactjs-localstorage";

const socket = io(`${process.env.REACT_APP_API_PATH}`);

const MyProfile = () => {
	const { databyEmail } = useAPI();
	const [openRecruted, setOpenRecruted] = useState(false);
	const [openChat, setOpenChat] = useState(false);
	const [chat, setChat] = useState();
	const [groupChat, setGroupChat] = useState(false);
	const lastMsg = reactLocalStorage.getObject("lastMsg")?.lastMsg;
	const room = "Group-Chat";
	const [groupChats, setGroupChats] = useState();
	useEffect(() => {
		if (room) {
			fetch(`${process.env.REACT_APP_API_PATH}/chatsbyroom/${room}`)
				.then((res) => res.json())
				.then((data) => {
					setGroupChats(data?.chats);
				});
		}
	}, [room]);
	const [unread, setUnread] = useState(0);
	useEffect(() => {
		const lastseenIndex = groupChats?.findIndex(
			(msg) => msg?.msgId === lastMsg?.msgId,
		);
		const totalMsg = groupChats?.length;
		setUnread(totalMsg - (lastseenIndex + 1));
	}, [groupChats, lastMsg]);

	useEffect(() => {
		if (databyEmail?.url) {
			fetch(`${process.env.REACT_APP_API_PATH}/chatsbyroom/${databyEmail?.url}`)
				.then((res) => res.json())
				.then((data) => {
					setChat(data);
				});
		}
	}, [openChat, databyEmail]);
	const { pwaInstall } = useReactPWAInstall();

	const handleClick = () => {
		pwaInstall({ title: "Install As App", logo: "/icon-192x192.png" });
	};

	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	const [iphone, setIphone] = useState(false);
	const [iphoneOpen, setIphoneOpen] = useState(false);
	useEffect(() => {
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			setIphone(true);
		}
	}, [userAgent]);
	const [openShare, setOpenShare] = useState(false);
	const [openProfile, setOpenProfile] = useState(false);

	return (
		<Box sx={{ px: 2 }}>
			{databyEmail?._id ? (
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					minHeight='80vh'>
					<Paper elevation={3} sx={{ p: 2, borderRadius: "17px", my: 2 }}>
						<Grid container spacing={2}>
							<Grid item md={3} sm={6} xs={12} sx={{ mx: "auto" }}>
								<Box>
									<img
										src={databyEmail?.profile?.imageLink2 || "/user.jpg"}
										style={{
											width: "100%",
											height: "100%",
											borderRadius: "15px",
										}}
										alt=''
									/>
								</Box>
							</Grid>
							<Grid item md={3} sm={6} xs={12} sx={{ mx: "auto" }}>
								{" "}
								<Box
									sx={{
										textAlign: "left",
									}}>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold", fontSize: "1.8rem" }}>
										{databyEmail?.headline?.title || "N/A"}
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
										{databyEmail?.headline?.subtitle || "N/A"}
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold", fontSize: "1rem" }}>
										Joined :- {databyEmail?.joiningTime || "N/A"}
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h6'>
										Role :- {databyEmail?.role || "N/A"}
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='subtitle2'>
										Recruited By :- {databyEmail?.recuretedBy || "N/A"}
									</Typography>
									<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
										<LocalPhoneIcon className='textColor' sx={{ mr: 1 }} />
										<Typography
											className='textColor'
											sx={{ fontWeight: "bold" }}>
											{databyEmail?.phone || "N/A"}
										</Typography>
									</Box>
									<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
										<EmailIcon className='textColor' sx={{ mr: 1 }} />
										<Typography
											className='textColor'
											sx={{ fontWeight: "bold" }}>
											{databyEmail?.email || "N/A"}
										</Typography>
									</Box>
									<a
										className='textColor'
										href={`${window.location.origin.toString()}/@${databyEmail?.url
											}`}>
										<Box
											sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
											<PermIdentityIcon className='textColor' sx={{ mr: 1 }} />
											<Typography
												className='textColor'
												sx={{ fontWeight: "bold" }}>
												{`${window.location.origin.toString()}/@${databyEmail?.url
													}`}
											</Typography>
										</Box>
									</a>
								</Box>
							</Grid>
							<Grid
								item
								md={6}
								sm={6}
								xs={12}
								sx={{ mx: "auto", textAlign: "left" }}>
								<Grid
									container
									spacing={1}
									sx={{
										my: 1,
										flexDirection: { xs: "column", sm: "row" },
									}}>
									<Grid item md={6} sm={6} xs={12}>
										<Button
											onClick={() => {
												setOpenShare(true);
											}}
											variant='contained'
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												width: "100%",
												py: 1,
											}}>
											<ShareIcon sx={{ mr: 0.7 }} /> Share
										</Button>
									</Grid>
									<Grid item md={6} sm={6} xs={12}>
										<Button
											onClick={() =>
												window.open(`/@${databyEmail?.url}`, "_blank").focus()
											}
											variant='contained'
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												width: "100%",
												py: 1,
											}}>
											<FaceIcon sx={{ mr: 0.7 }} /> My Card
										</Button>
									</Grid>
									{databyEmail?.role === "Admin" && (
										<Grid item md={6} sm={6} xs={12}>
											<Button
												onClick={() => {
													setOpenRecruted(true);
												}}
												className=' border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													width: "100%",
													py: 1,
												}}
												variant='contained'>
												<PeopleIcon sx={{ mr: 0.7, color: "red" }} /> Recruited
												By Me
											</Button>
										</Grid>
									)}
									<Grid item md={6} sm={6} xs={12}>
										<Button
											onClick={() => {
												setOpenProfile(true);
											}}
											className=' border'
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												width: "100%",
												py: 1,
											}}
											variant='contained'>
											<FaceIcon sx={{ mr: 0.7 }} /> Update Profile
										</Button>
									</Grid>

									<Grid item md={6} sm={6} xs={12}>
										{iphone ? (
											<Button
												onClick={() => setIphoneOpen(true)}
												className=' border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													width: "100%",
													py: 1,
												}}
												variant='contained'>
												<BoltIcon />
												<AppleIcon sx={{ mr: 0.7 }} /> Install On IOS
											</Button>
										) : (
											<Button
												onClick={handleClick}
												className=' border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													width: "100%",
													py: 1,
												}}
												variant='contained'>
												<BoltIcon />
												<AndroidIcon sx={{ mr: 0.7 }} />
												Install App
											</Button>
										)}
									</Grid>

									<Grid item md={6} sm={6} xs={12} className='onlineProfile'>
										<Button
											onClick={() => {
												setGroupChat(true);
												socket.emit("join_room", room);
											}}
											className=' border'
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												width: "100%",
												py: 1,
											}}
											variant='contained'>
											<ChatIcon sx={{ mr: 1 }} /> Group Chat
											<Chip
												sx={{ height: "auto !important", ml: 1.5 }}
												color={unread > 0 ? "error" : "success"}
												label={`${unread} New`}
											/>
										</Button>
									</Grid>

									<Grid item md={6} sm={6} xs={12} className='onlineProfile'>
										{databyEmail?.role !== "Admin" && (
											<Button
												onClick={() => {
													setOpenChat(true);
													socket.emit("join_room", databyEmail?.url);
												}}
												className=' border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													width: "100%",
													py: 1,
												}}
												variant='contained'>
												<ChatIcon sx={{ mr: 1 }} /> Chat With Admin{" "}
												<Chip
													sx={{ height: "auto !important", ml: 1.5 }}
													color={
														chat?.chats?.filter((d) => d?.clientSeen === false)
															?.length > 0
															? "error"
															: "success"
													}
													label={
														chat?.chats?.filter((d) => d?.clientSeen === false)
															?.length
													}
												/>
											</Button>
										)}
									</Grid>
								</Grid>
							</Grid>
							<Grid item md={12} sm={12} xs={12}>
								<Grid container spacing={2} className='icons'>
									{databyEmail?.links?.facebookDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`${databyEmail?.links?.facebook}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<FacebookOutlinedIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												Facebook
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.emailDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`mailto:${databyEmail?.links?.email}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<EmailOutlinedIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												Email
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.whatsappDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`https://api.whatsapp.com/send?phone=${databyEmail?.links?.whatsapp}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<WhatsAppIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												Whatsapp
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.youtubeDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`${databyEmail?.links?.youtube}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<SubscriptionsOutlinedIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												Youtube
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.callDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(`tel:${databyEmail?.links?.call}`)
												}
												color='primary'
												aria-label='add'>
												<LocalPhoneIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												CALL ME
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.smsDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`sms:${databyEmail?.links?.sms}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<SmsIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												TEXT ME
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.twitterDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`${databyEmail?.links?.twitter}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<TwitterIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												Twitter
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.instagramDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`${databyEmail?.links?.instagram}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<InstagramIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												Instagram
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.linkedinDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`${databyEmail?.links?.linkedin}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<LinkedInIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												LinkedIn
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.pinterestDashB === true && (
										<Grid
											item
											xs
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}>
											<Fab
												onClick={() =>
													window.open(
														`${databyEmail?.links?.pinterest}`,
														"_blank",
													)
												}
												color='primary'
												aria-label='add'>
												<PinterestIcon />
											</Fab>
											<Typography
												className='textColor'
												sx={{ mt: 1, fontWeight: "bold" }}
												variant='button'
												display='block'
												gutterBottom>
												Pinterest
											</Typography>
										</Grid>
									)}
									{databyEmail?.links?.alignableDashB === true && (
										<Grid item xs>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													cursor: "pointer",
												}}
												onClick={() =>
													window.open(
														`${databyEmail?.links?.alignable}`,
														"_blank",
													)
												}>
												<img
													className='borderColor'
													style={{
														width: "56px",
														height: "56px",
														border: "1px solid",
														borderRadius: "50%",
													}}
													src={"/alignable.png"}
													alt='America Solar'
												/>
												<Typography
													className='textColor'
													sx={{ mt: 1, fontWeight: "bold" }}
													variant='button'
													display='block'
													gutterBottom>
													Alignable
												</Typography>
											</Box>
										</Grid>
									)}
									{databyEmail?.links?.nextdoorDashB === true && (
										<Grid item xs>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													cursor: "pointer",
												}}
												onClick={() =>
													window.open(
														`${databyEmail?.links?.nextdoor}`,
														"_blank",
													)
												}>
												<img
													className='borderColor'
													style={{
														width: "56px",
														height: "56px",
														border: "1px solid",
														borderRadius: "50%",
													}}
													src={"/nextdoor.png"}
													alt='America Solar'
												/>
												<Typography
													className='textColor'
													sx={{ mt: 1, fontWeight: "bold" }}
													variant='button'
													display='block'
													gutterBottom>
													Nextdoor
												</Typography>
											</Box>
										</Grid>
									)}
									{databyEmail?.links?.zoomDashB === true && (
										<Grid item xs>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													cursor: "pointer",
												}}
												onClick={() =>
													window.open(`${databyEmail?.links?.zoom}`, "_blank")
												}>
												<img
													className='borderColor'
													style={{
														width: "56px",
														height: "56px",
														border: "1px solid",
														borderRadius: "50%",
													}}
													src={"/zoom.png"}
													alt='America Solar'
												/>
												<Typography
													className='textColor'
													sx={{ mt: 1, fontWeight: "bold" }}
													variant='button'
													display='block'
													gutterBottom>
													Zoom
												</Typography>
											</Box>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Box>
			) : (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			{openChat && (
				<Chat
					openChat={openChat}
					setOpenChat={setOpenChat}
					user={databyEmail}
					socket={socket}
					room={databyEmail?.url}
				/>
			)}

			{openShare && (
				<ShareManage
					openShare={openShare}
					setOpenShare={setOpenShare}
					user={databyEmail}
				/>
			)}
			{iphoneOpen && (
				<HowToInstall iphoneOpen={iphoneOpen} setIphoneOpen={setIphoneOpen} />
			)}
			{openRecruted && (
				<RecrutedByUser
					openRecruted={openRecruted}
					setOpenRecruted={setOpenRecruted}
					me={databyEmail?.url}
				/>
			)}

			{openProfile && (
				<EditProfile
					open={openProfile}
					setOpen={setOpenProfile}
					user={databyEmail}
				/>
			)}
			{groupChat && (
				<GroupChat
					open={groupChat}
					setOpen={setGroupChat}
					socket={socket}
					room={room}
				/>
			)}
		</Box>
	);
};

export default MyProfile;
