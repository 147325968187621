import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AllClicks = () => {
	const [clicks, setClicks] = useState([]);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/Clicks`)
			.then((res) => res.json())
			.then((data) => {
				setClicks(data?.reverse());
			});
	}, []);

	const columns = [
		{
			field: "url",
			headerName: "URL",
			flex: 1,
		},
		{
			field: "date",
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						{new Date(params?.value).toLocaleDateString()}{" "}
						{new Date(params?.value).toLocaleTimeString()}
					</>
				);
			},
		},
		{
			field: "profileID",
			headerName: "Profile ID",
			flex: 1,
		},
		{
			field: "type",
			headerName: "Type",
			flex: 1,
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				All Clicks
			</Typography>

			{clicks && (
				<Box sx={{ width: "100%", height: "75vh" }}>
					<DataGrid
						rows={clicks}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!clicks}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default AllClicks;
