import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	InputLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAPI } from "../../../../apiContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "50vw", sm: "70vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "left",
};

const SingleUser = ({ user, open, setOpen }) => {
	const { databyEmail } = useAPI();
	return (
		<div>
			{databyEmail ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Box sx={{ mb: 2 }}>
								<Typography
									className='textColor'
									variant='body'
									gutterBottom
									sx={{ fontWeight: "bold", textAlign: "center" }}>
									New User Scheduled by using invitation code : "
									<span style={{ color: "red" }}>{user?.invitationCode}</span>"
									on <span style={{ color: "red" }}>{user?.submitTime}</span>
								</Typography>
							</Box>

							<InputLabel htmlFor='input-with-icon-adornment'>Name</InputLabel>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								value={user?.name}
								disabled
							/>
							<InputLabel htmlFor='input-with-icon-adornment'>Email</InputLabel>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								type='email'
								value={user?.email}
								disabled
							/>
							<InputLabel htmlFor='input-with-icon-adornment'>
								Phone Number
							</InputLabel>
							<TextField
								required
								type='tel'
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								value={user?.phoneNumber}
								disabled
							/>
							<InputLabel htmlFor='input-with-icon-adornment'>
								Tell Us Something You Need To Know
							</InputLabel>
							<TextField
								required
								multiline
								rows={4}
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								value={user?.details}
								disabled
							/>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</div>
	);
};

export default SingleUser;
