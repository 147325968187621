import React, { useContext, useState, createContext } from "react";
import axios from "axios";
import GetAuth from "./GetAuth";

const APIContext = createContext();

export function APIContextProvider({ children }) {
	const [info, setInfo] = useState([]);
	React.useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_PATH}/colorBorder`).then((res) => {
			setInfo(res.data?.colorBorder);
		});
	}, []);
	const [data, setData] = useState();
	React.useEffect(() => {
		const url = window.location.pathname.split("/")[1];
		fetch(`${process.env.REACT_APP_API_PATH}/alluser/${url || "owner"}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	const [databyEmail, setDatabyEmail] = useState();
	const [user] = GetAuth();
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`)
			.then((res) => res.json())
			.then((data) => setDatabyEmail(data))
			.catch((error) => {
				setDatabyEmail({ email: "Not Found" });
			});
	}, [user?.email]);
	const [allUsers, setAllUsers] = useState();
	React.useEffect(() => {
		window.location.pathname.includes("dashboard") &&
			fetch(`${process.env.REACT_APP_API_PATH}/allusersforassign`)
				.then((res) => res.json())
				.then((data) => {
					setAllUsers(data.reverse());
				});
	}, []);
	return (
		<APIContext.Provider
			value={{
				data: data,
				info: info,
				databyEmail: databyEmail,
				allUsers,
			}}>
			{children}
		</APIContext.Provider>
	);
}

export function useAPI() {
	const context = useContext(APIContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
