import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Fade,
	Modal,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SkipNextIcon from "@mui/icons-material/SkipNext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "70vw", sm: "80vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const TermsAndCondition = ({ open, setOpen, data }) => {
	const [terms, setTerms] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/terms`)
			.then((res) => res.json())
			.then((data) => {
				setTerms(data);
			});
	}, []);
	return (
		<Container component='main' maxWidth='xs'>
			{data && terms ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<Button
								onClick={() => setOpen(false)}
								className='confirm'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}>
								Skip Now
								<SkipNextIcon sx={{ ml: 0.5 }} />
							</Button>
							<Typography
								component='h1'
								variant='h5'
								className='textColor'
								sx={{ fontWeight: "bold" }}>
								Terms of Service
							</Typography>
							<Typography
								variant='body'
								className='textColor'
								sx={{ my: 3, whiteSpace: "pre-line" }}>
								{terms?.terms?.terms}
							</Typography>
							<Button
								className='confirm'
								onClick={() => {
									setOpen(false);
								}}
								fullWidth
								variant='contained'
								sx={{
									mt: 1,
								}}>
								I Understand
							</Button>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</Container>
	);
};

export default TermsAndCondition;
