import React, { useEffect, useState } from "react";
import { FormControl, TextField, Select, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import BadgeIcon from "@mui/icons-material/Badge";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import axios from "axios";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 1 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const ShareManage = ({ shareText, setShareText }) => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		setShareText(" ");
	};

	const [edit, setEdit] = React.useState(false);

	const [data, setData] = useState();
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_PATH}/shareTexts`).then((res) => {
			setData(res.data);
		});
	}, []);
	return (
		<div>
			<Box sx={{ width: "100%", position: "relative" }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						value={value}
						onChange={handleChange}
						variant='fullWidth'
						aria-label='basic tabs example'>
						<Tab
							icon={<BadgeIcon />}
							label='General Invite'
							{...a11yProps(0)}
						/>
						<Tab
							icon={<GroupAddIcon />}
							label='Entrepreneur Invite'
							{...a11yProps(1)}
						/>
						<Tab
							icon={<EventAvailableIcon />}
							label='FM Invite'
							{...a11yProps(2)}
						/>
						<Tab
							icon={<MeetingRoomIcon />}
							label='FBO Invite'
							{...a11yProps(3)}
						/>
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<FormControl fullWidth sx={{ mb: 3 }}>
						<Select
							value={shareText}
							onChange={(e) => {
								setShareText(e.target.value);
								setEdit(false);
							}}>
							<MenuItem value=' '>
								<em>Select Messages</em>
							</MenuItem>
							{data
								?.filter((u) => u?.select === "General-Invite")
								?.map((details) => (
									<MenuItem value={details?.message}>
										{details?.message}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					{!edit && (
						<EditIcon
							className='textColor borderColor'
							onClick={() => setEdit(true)}
							sx={{
								cursor: "pointer",
								borderRadius: "5px",
								zIndex: "1",
								width: "1.4em",
								height: "1.4em",
								border: "1px solid",
								position: "absolute",
								top: "12rem",
								right: "15px",
							}}
						/>
					)}
					{edit ? (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							defaultValue={shareText}
							label='Share Text'
							onChange={(e) => setShareText(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					) : (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							value={shareText}
							onChange={(e) => setShareText(e.target.value)}
							label='Share Text'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				</TabPanel>
				<TabPanel value={value} index={1}>
					<FormControl fullWidth sx={{ mb: 3 }}>
						<Select
							value={shareText}
							onChange={(e) => {
								setShareText(e.target.value);
								setEdit(false);
							}}>
							<MenuItem value=' '>
								<em>Select Messages</em>
							</MenuItem>
							{data
								?.filter((u) => u?.select === "Entrepreneur-Invite")
								?.map((details) => (
									<MenuItem value={details?.message}>
										{details?.message}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					{!edit && (
						<EditIcon
							className='textColor borderColor'
							onClick={() => setEdit(true)}
							sx={{
								cursor: "pointer",
								borderRadius: "5px",
								zIndex: "1",
								width: "1.4em",
								height: "1.4em",
								border: "1px solid",
								position: "absolute",
								top: "12rem",
								right: "15px",
							}}
						/>
					)}
					{edit ? (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							defaultValue={shareText}
							label='Share Text'
							onChange={(e) => setShareText(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					) : (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							value={shareText}
							onChange={(e) => setShareText(e.target.value)}
							label='Share Text'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				</TabPanel>
				<TabPanel value={value} index={2}>
					<FormControl fullWidth sx={{ mb: 3 }}>
						<Select
							value={shareText}
							onChange={(e) => {
								setShareText(e.target.value);
								setEdit(false);
							}}>
							<MenuItem value=' '>
								<em>Select Messages</em>
							</MenuItem>
							{data
								?.filter((u) => u?.select === "FM-Invite")
								?.map((details) => (
									<MenuItem value={details?.message}>
										{details?.message}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					{!edit && (
						<EditIcon
							className='textColor borderColor'
							onClick={() => setEdit(true)}
							sx={{
								cursor: "pointer",
								borderRadius: "5px",
								zIndex: "1",
								width: "1.4em",
								height: "1.4em",
								border: "1px solid",
								position: "absolute",
								top: "12rem",
								right: "15px",
							}}
						/>
					)}
					{edit ? (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							defaultValue={shareText}
							label='Share Text'
							onChange={(e) => setShareText(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					) : (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							value={shareText}
							onChange={(e) => setShareText(e.target.value)}
							label='Share Text'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				</TabPanel>
				<TabPanel value={value} index={3}>
					<FormControl fullWidth sx={{ mb: 3 }}>
						<Select
							value={shareText}
							onChange={(e) => {
								setShareText(e.target.value);
								setEdit(false);
							}}>
							<MenuItem value=' '>
								<em>Select Messages</em>
							</MenuItem>
							{data
								?.filter((u) => u?.select === "FBO-Invite")
								?.map((details) => (
									<MenuItem value={details?.message}>
										{details?.message}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					{!edit && (
						<EditIcon
							className='textColor borderColor'
							onClick={() => setEdit(true)}
							sx={{
								cursor: "pointer",
								borderRadius: "5px",
								zIndex: "1",
								width: "1.4em",
								height: "1.4em",
								border: "1px solid",
								position: "absolute",
								top: "12rem",
								right: "15px",
							}}
						/>
					)}
					{edit ? (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							defaultValue={shareText}
							label='Share Text'
							onChange={(e) => setShareText(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					) : (
						<TextField
							required
							fullWidth
							multiline
							rows={9}
							value={shareText}
							onChange={(e) => setShareText(e.target.value)}
							label='Share Text'
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				</TabPanel>
			</Box>
		</div>
	);
};

export default ShareManage;
