import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useEffect } from "react";
import { useState } from "react";
import {
	Backdrop,
	CircularProgress,
	Grid,
	MenuItem,
	Select,
} from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import GetAuth from "../../GetAuth";
import { useAPI } from "../../apiContext";

const SubmitInfo = ({ setState }) => {
	const [user, loading] = GetAuth();
	const [url, setUrl] = React.useState();
	const [users, setUsers] = useState();
	const { data } = useAPI();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/allusersurl`)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data);
			});
	}, []);

	const [search, setSearch] = useState("");
	const handleInputChange = (e) => {
		const dm = e.target.value;
		const str = dm.toString();
		const debug = users.filter((user) => user?.toLowerCase() === str);
		setSearch(
			debug?.length === 0
				? false
				: "This username already exists, Please try a different one",
		);
		setUrl(str?.toLowerCase().replace(/[^a-zA-Z0-9]/g, ""));
	};

	const [submitting, setSubmitting] = useState(false);

	const { handleSubmit, register } = useForm();
	const [role, setRole] = useState("");
	const onSubmit = ({
		name,
		phone,
		addressLine1,
		state,
		city,
		zip,
		country,
	}) => {
		setSubmitting(true);
		axios
			.post(`${process.env.REACT_APP_API_PATH}/makeNewUser`, {
				url,
				role,
				email: user?.email,
				name: name,
				phone: phone,
				recuretedBy: data?.url,
				address: { addressLine1, state, city, zip, country },
				time: new Date().toLocaleString("en-US", {
					timeZone: "America/Los_Angeles",
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
					second: "2-digit",
					timeZoneName: "short",
				}),
				chat: {
					chatRoom: url,
					chats: [],
				},
				payment: {
					user: url,
					email: user?.email,
					defaultMethod: "",
					status: "",
					submitTime: "",
					w9Info: {
						photoId: "",
						w9AddressLine1: "",
						w9AddressLine2: "",
						w9City: "",
						w9Country: "",
						w9FullName: "",
						w9Phone: "",
						w9Ssi: "",
						w9State: "",
						w9TinNumner: "",
						w9Zip: "",
					},
					paypalInfo: {
						paypalBank: "",
						paypalEmail: "",
						paypalPhone: "",
						paypalUsername: "",
					},
					wireTransferInfo: {
						wireTransferAccNumber: "",
						wireTransferAddressLine1: "",
						wireTransferAddressLine2: "",
						wireTransferCity: "",
						wireTransferCountry: "",
						wireTransferFullName: "",
						wireTransferPhone: "",
						wireTransferRoutingNumber: "",
						wireTransferState: "",
						wireTransferZip: "",
					},
					venmoInfo: {
						qrImage: "",
						venmoEmail: "",
						venmoPhone: "",
						venmoUsername: "",
					},
					cashAppInfo: {
						cashAppCashtag: "",
						cashAppEmail: "",
						cashAppPhone: "",
						qrImage: "",
					},
				},
			})
			.then(function (response) {
				setSubmitting(false);
				setState(true);
				Swal.fire({
					icon: "success",
					title: "Your Successfully Requested For Joining The Team",
					showConfirmButton: true,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<Box style={{ marginTop: 3, textAlign: "center" }}>
			<Box>
				<Avatar sx={{ mx: "auto", my: 0.7 }} className='bgColor'>
					<PersonAddAltIcon />
				</Avatar>
				<Typography
					component='h1'
					variant='h5'
					className='textColor'
					sx={{ fontWeight: "bold" }}>
					Request To Join Team
				</Typography>
			</Box>
			<form
				onSubmit={handleSubmit(onSubmit)}
				method='post'
				style={{ display: "flex", flexDirection: "column" }}>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
						<Grid container>
							<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
								<TextField
									required
									fullWidth
									size='small'
									id='name'
									label='Your Name'
									name='name'
									autoComplete='Name'
									autoFocus
									sx={{ maxWidth: "100%" }}
									{...register("name", { required: true })}
								/>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
								<TextField
									required
									fullWidth
									size='small'
									id='email'
									label='Email Address'
									name='email'
									autoComplete='email'
									autoFocus
									value={user?.email}
									sx={{ maxWidth: "100%" }}
								/>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
								<TextField
									required
									fullWidth
									size='small'
									id='phone'
									label='Your Phone Number'
									name='phone'
									autoComplete='phone'
									autoFocus
									sx={{ maxWidth: "100%" }}
									{...register("phone", { required: true })}
								/>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
								<Select
									size='small'
									sx={{ width: "100%" }}
									required
									fullWidth
									onChange={(e) => {
										setRole(e.target.value);
									}}
									value={role}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}>
									<MenuItem value={""}>Select Your Role</MenuItem>
									<MenuItem value={"FBO"}>FBO</MenuItem>
									<MenuItem value={"Manager"}>Manager</MenuItem>
									<MenuItem value={"Senior Manager"}>Senior Manager</MenuItem>
									<MenuItem value={"Vice President"}>Vice President</MenuItem>
									<MenuItem value={"Senior President"}>
										Senior President
									</MenuItem>
									<MenuItem value={"President"}>President</MenuItem>
								</Select>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
								<TextField
									required
									fullWidth
									size='small'
									sx={{ width: "100%" }}
									id='outlined-basic'
									type={"text"}
									name='Address Line 1'
									label='Address Line 1'
									{...register("addressLine1", { required: true })}
								/>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
								<Grid container spacing={1.5}>
									<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
										<TextField
											sx={{ width: "100%" }}
											fullWidth
											size='small'
											id='outlined-basic'
											type={"text"}
											name='City'
											label='City'
											{...register("city", { required: true })}
										/>
									</Grid>
									<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
										<TextField
											required
											fullWidth
											size='small'
											sx={{ width: "100%" }}
											id='outlined-basic'
											type={"text"}
											name='State/Region'
											label='State/Region'
											{...register("state", { required: true })}
										/>
									</Grid>
									<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
										<TextField
											required
											fullWidth
											size='small'
											sx={{ width: "100%" }}
											id='outlined-basic'
											type={"text"}
											name='Zip/Postal Code'
											label='Zip/Postal Code'
											{...register("zip", { required: true })}
										/>
									</Grid>
									<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
										<TextField
											required
											fullWidth
											size='small'
											sx={{ width: "100%" }}
											id='outlined-basic'
											type={"text"}
											name='Country'
											label='Country'
											{...register("country", { required: true })}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								md={12}
								xs={12}
								sx={{ mx: "auto", my: 1 }}
								className='username'>
								<input
									className='borderColor textColor'
									placeholder='Select a username *'
									style={{
										placeholderColor: "red !important",
										padding: "15px 15px",
										border: "1px solid ",
										borderRadius: "4px",
										width: "100%",
										boxSizing: " border-box",
									}}
									type='text'
									onChange={(e) => handleInputChange(e)}
								/>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
								{search === false ? (
									<p></p>
								) : (
									<Typography sx={{ color: "red !important" }}>
										{search}
									</Typography>
								)}
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
								{search === false && url ? (
									<Button
										type='submit'
										className='confirm'
										fullWidth
										size='small'
										variant='contained'>
										Request Now
									</Button>
								) : (
									<Button
										disabled
										fullWidth
										size='small'
										variant='contained'
										sx={{
											backgroundColor: "gray !important",
										}}>
										Request Now
									</Button>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading || submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default SubmitInfo;
