import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DoneIcon from "@mui/icons-material/Done";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3, height: 350, overflowY: "scroll" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const BlockedScheduleDate = () => {
	const [deleted, setDeleted] = useState(false);
	const [allDate, setAllDate] = useState();
	const [selectDate, setSelectDate] = useState();
	const [submitting, setSubmitting] = useState(false);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/BlockedScheduleDate`)
			.then((res) => res.json())
			.then((data) => {
				setAllDate(data);
			});
	}, [deleted, submitting]);
	const days = [
		"Saturday",
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
	];
	const [day, setDay] = useState(days[0]);
	const [time, setTime] = React.useState("");
	const [value, setValue] = React.useState(0);
	const [manageScheduleTime, setManageScheduleTime] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/ManageScheduleTime`)
			.then((res) => res.json())
			.then((data) => {
				setManageScheduleTime(data?.filter((item) => item.day === day));
			});
	}, [day, deleted, submitting]);
	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/BlockedScheduleDate/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That Date has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const dates = allDate?.map((day) => day.selectedDate).join(",");
	const customDayRenderer = (date, selectedDates, pickersDayProps) => {
		const stringifiedDate = date?.toLocaleString()?.split(", ")[0];
		if (dates.includes(stringifiedDate)) {
			return <PickersDay {...pickersDayProps} disabled />;
		}
		return <PickersDay {...pickersDayProps} />;
	};

	const columns = [
		{
			field: "selectedDate",
			headerName: "Blocked",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 170, filterable: false,
			renderCell: (params) => {
				return (
					<Button
						className='button border'
						onClick={() => handleDelete(params?.row?._id)}
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'>
						<DeleteIcon />
					</Button>
				);
			},
		},
	];
	return (
		<Container sx={{ pt: 2, minHeight: "100vh" }} className='timeDate'>
			<Grid container spacing={2}>
				<Grid item md={4} xs={12} sx={{ mx: "auto" }}>
					<Typography
						className='textColor'
						sx={{ mb: 1, fontWeight: "bold" }}
						variant='h4'
						component='div'
						gutterBottom>
						Manage Times
					</Typography>
					<Box
						sx={{
							flexGrow: 1,
							display: "flex",
							height: 350,
						}}>
						<Tabs
							orientation='vertical'
							variant='scrollable'
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
							aria-label='Vertical tabs example'
							sx={{ borderRight: 1, borderColor: "divider" }}>
							<Tab
								onClick={() => setDay("Saturday")}
								label='Saturday'
								{...a11yProps(0)}
							/>
							<Tab
								onClick={() => setDay("Sunday")}
								label='Sunday'
								{...a11yProps(1)}
							/>
							<Tab
								onClick={() => setDay("Monday")}
								label='Monday'
								{...a11yProps(2)}
							/>
							<Tab
								onClick={() => setDay("Tuesday")}
								label='Tuesday'
								{...a11yProps(3)}
							/>
							<Tab
								onClick={() => setDay("Wednesday")}
								label='Wednesday'
								{...a11yProps(4)}
							/>
							<Tab
								onClick={() => setDay("Thursday")}
								label='Thursday'
								{...a11yProps(5)}
							/>
							<Tab
								onClick={() => setDay("Friday")}
								label='Friday'
								{...a11yProps(6)}
							/>
						</Tabs>
						{[0, 1, 2, 3, 4, 5, 6].map((t, i) => (
							<TabPanel value={value} index={i}>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<TextField
										id='time'
										type='time'
										value={time}
										onChange={(e) => setTime(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											step: 300,
										}}
										sx={{ width: 150 }}
									/>

									{time !== "" ? (
										<DoneIcon
											onClick={() => {
												setSubmitting(true);
												const data = {
													day: day,
													time: time,
												};
												setDeleted(true);
												axios
													.post(
														`${process.env.REACT_APP_API_PATH}/ManageScheduleTime`,
														data,
													)
													.then(function (response) {
														setSubmitting(false);
														setTime("");
													})
													.catch(function (error) {
														console.log(error);
														setSubmitting(false);
													});
											}}
											sx={{ border: "1px solid", height: 55 }}
											className='textColor border'
											fontSize='large'
										/>
									) : (
										<DoneIcon
											sx={{ border: "1px solid", height: 55, color: "gray" }}
											className=' border'
											fontSize='large'
										/>
									)}
								</Box>
								{manageScheduleTime
									?.sort(
										(a, b) =>
											new Date("1970/01/01 " + a.time) -
											new Date("1970/01/01 " + b.time),
									)
									?.map((time, i) => (
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<TextField
												id='time'
												type='time'
												value={time.time}
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													step: 300,
												}}
												sx={{ width: 150 }}
											/>
											<RemoveCircleOutlineIcon
												onClick={() => {
													setSubmitting(true);
													axios
														.delete(
															`${process.env.REACT_APP_API_PATH}/ManageScheduleTime/${time?._id}`,
														)
														.then(function (response) {
															setSubmitting(false);
														})
														.catch(function (error) {
															console.log(error);
															setSubmitting(false);
														});
												}}
												sx={{ border: "1px solid", height: 55 }}
												className='textColor border'
												fontSize='large'
											/>
										</Box>
									))}
							</TabPanel>
						))}
					</Box>
				</Grid>
				<Grid item md={4} xs={12} sx={{ mx: "auto" }}>
					<Typography
						className='textColor'
						sx={{ mb: 1, fontWeight: "bold" }}
						variant='h4'
						component='div'
						gutterBottom>
						Add Blocked Date
					</Typography>
					<Box className='calander schedule'>
						{allDate && (
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<CalendarPicker
									renderDay={customDayRenderer}
									disablePast
									date={selectDate}
									onChange={(newDate) => setSelectDate(newDate)}
								/>
							</LocalizationProvider>
						)}
					</Box>

					<Button
						onClick={() => {
							setSubmitting(true);
							const data = {
								selectedDate: selectDate.toLocaleString()?.split(", ")[0],
								submitTime: new Date().toLocaleString("en-US", {
									timeZone: "America/Los_Angeles",
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
									hour: "2-digit",
									minute: "2-digit",
									second: "2-digit",
									timeZoneName: "short",
								}),
							};
							axios
								.post(
									`${process.env.REACT_APP_API_PATH}/BlockedScheduleDate`,
									data,
								)
								.then(function (response) {
									setSubmitting(false);
									Swal.fire({
										icon: "success",
										title: "Successfully Added",
										showConfirmButton: true,
										timer: 2500,
									});
								})
								.catch(function (error) {
									console.log(error);
								});
						}}
						className='button'
						type='submit'
						variant='contained'
						sx={{
							width: "100%",
							px: 3,
							mt: -5,
							fontWeight: "bold",
							borderRadius: "25px",
						}}>
						Block Date
					</Button>
				</Grid>
				<Grid item md={8} xs={12} sx={{ mx: "auto" }}>
					{allDate && (
						<Box
							sx={{
								height: "75vh",
								maxWidth: "500px",
								"& .MuiButton-root": {
									border: "1px solid",
								},
							}}>
							<DataGrid
								rows={allDate}
								getRowId={(row) => row?._id}
								columns={columns}
								components={{
									Toolbar: GridToolbar,
								}}
								disableSelectionOnClick
							/>
						</Box>
					)}
					{/* 	<Paper
						className='container'
						sx={{
							overflow: "auto",
							maxHeight: "95vh",
						}}>
						<Table size='small' stickyHeader aria-label='sticky table'>
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='center' colSpan={3}>
										<Typography
											className='textColor'
											sx={{ fontWeight: "bold" }}
											variant='h4'>
											All Blocked Dates
										</Typography>
										<Typography className='textColor'>
											Last Added in {allDate?.[0]?.submitTime || "N/A"}
										</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align='left'>No</TableCell>
									<TableCell align='left'>Blocked</TableCell>
									<TableCell align='center'>Action</TableCell>
								</TableRow>
							</TableHead>
							{allDate?.length > 0 ? (
								<TableBody sx={{ td: { py: 1 } }}>
									{allDate?.reverse()?.map((date, key) => (
										<TableRow
											key={key}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											<TableCell align='left'>{count++}</TableCell>
											<TableCell align='left'>{date?.selectedDate}</TableCell>
											<TableCell align='center'>
												<Button
													className='button border'
													onClick={() => handleDelete(date?._id)}
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<DeleteIcon />
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='center'>N/A</TableCell>
									</TableRow>
								</TableHead>
							)}
						</Table>
					</Paper> */}
				</Grid>
			</Grid>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !allDate}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default BlockedScheduleDate;
