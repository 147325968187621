import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	CircularProgress,
	Backdrop,
	FormControl,
	TextField,
	Button,
	Select,
	MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import BadgeIcon from "@mui/icons-material/Badge";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import axios from "axios";
import SmsIcon from "@mui/icons-material/Sms";
import Swal from "sweetalert2";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 1 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	width: { md: "40vw", sm: "60vw", xs: "90vw" },
	p: 2,
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const SendSms = ({ open, setOpen, user }) => {
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
		setSelectText(" ");
	};
	const [submitting, setSubmitting] = useState(false);
	const [selectText, setSelectText] = React.useState(" ");
	const [shareText, setShareText] = React.useState("");
	const handleSendSms = () => {
		setSubmitting(true);
		const datas = { ...user, textSms: shareText || selectText };
		axios
			.post(`${process.env.REACT_APP_API_PATH}/MemoryJoggerListSendSms`, datas)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Sent",
					showConfirmButton: true,
					timer: 1500,
				});
				setOpen(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [edit, setEdit] = React.useState(false);

	const [data, setData] = useState();
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_PATH}/shareTexts`).then((res) => {
			setData(res.data);
		});
	}, []);
	return (
		<div>
			{data ? (
				<Modal
					className='manageShare'
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={setOpen}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Box sx={{ width: "100%", position: "relative" }}>
								<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
									<Tabs
										value={value}
										onChange={handleChange}
										variant='fullWidth'
										aria-label='basic tabs example'>
										<Tab
											icon={<BadgeIcon />}
											label='General Invite'
											{...a11yProps(0)}
										/>
										<Tab
											icon={<GroupAddIcon />}
											label='Entrepreneur Invite'
											{...a11yProps(1)}
										/>
										<Tab
											icon={<EventAvailableIcon />}
											label='FM Invite'
											{...a11yProps(2)}
										/>
										<Tab
											icon={<MeetingRoomIcon />}
											label='FBO Invite'
											{...a11yProps(3)}
										/>
									</Tabs>
								</Box>
								<TabPanel value={value} index={0}>
									<FormControl fullWidth sx={{ mb: 3 }}>
										<Select
											value={shareText}
											onChange={(e) => {
												setShareText(e.target.value);
												setEdit(false);
											}}>
											<MenuItem value=' '>
												<em>Select Messages</em>
											</MenuItem>
											{data
												?.filter((u) => u?.select === "General-Invite")
												?.map((details) => (
													<MenuItem value={details?.message}>
														{details?.message}
													</MenuItem>
												))}
										</Select>
									</FormControl>
									{!edit && (
										<EditIcon
											className='textColor borderColor'
											onClick={() => setEdit(true)}
											sx={{
												cursor: "pointer",
												borderRadius: "5px",
												zIndex: "1",
												width: "1.4em",
												height: "1.4em",
												border: "1px solid",
												position: "absolute",
												top: "12rem",
												right: "15px",
											}}
										/>
									)}
									{edit ? (
										<TextField
											required
											fullWidth
											multiline
											rows={9}
											defaultValue={shareText}
											label='Share Text'
											onChange={(e) => setShareText(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									) : (
										<TextField
											required
											fullWidth
											multiline
											rows={9}
											value={shareText}
											onChange={(e) => setShareText(e.target.value)}
											label='Share Text'
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								</TabPanel>
								<TabPanel value={value} index={1}>
									<FormControl fullWidth sx={{ mb: 3 }}>
										<Select
											value={shareText}
											onChange={(e) => {
												setShareText(e.target.value);
												setEdit(false);
											}}>
											<MenuItem value=' '>
												<em>Select Messages</em>
											</MenuItem>
											{data
												?.filter((u) => u?.select === "Entrepreneur-Invite")
												?.map((details) => (
													<MenuItem value={details?.message}>
														{details?.message}
													</MenuItem>
												))}
										</Select>
									</FormControl>
									{!edit && (
										<EditIcon
											className='textColor borderColor'
											onClick={() => setEdit(true)}
											sx={{
												cursor: "pointer",
												borderRadius: "5px",
												zIndex: "1",
												width: "1.4em",
												height: "1.4em",
												border: "1px solid",
												position: "absolute",
												top: "12rem",
												right: "15px",
											}}
										/>
									)}
									{edit ? (
										<TextField
											required
											fullWidth
											multiline
											rows={9}
											defaultValue={shareText}
											label='Share Text'
											onChange={(e) => setShareText(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									) : (
										<TextField
											required
											fullWidth
											multiline
											rows={9}
											value={shareText}
											onChange={(e) => setShareText(e.target.value)}
											label='Share Text'
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								</TabPanel>
								<TabPanel value={value} index={2}>
									<FormControl fullWidth sx={{ mb: 3 }}>
										<Select
											value={shareText}
											onChange={(e) => {
												setShareText(e.target.value);
												setEdit(false);
											}}>
											<MenuItem value=' '>
												<em>Select Messages</em>
											</MenuItem>
											{data
												?.filter((u) => u?.select === "FM-Invite")
												?.map((details) => (
													<MenuItem value={details?.message}>
														{details?.message}
													</MenuItem>
												))}
										</Select>
									</FormControl>
									{!edit && (
										<EditIcon
											className='textColor borderColor'
											onClick={() => setEdit(true)}
											sx={{
												cursor: "pointer",
												borderRadius: "5px",
												zIndex: "1",
												width: "1.4em",
												height: "1.4em",
												border: "1px solid",
												position: "absolute",
												top: "12rem",
												right: "15px",
											}}
										/>
									)}
									{edit ? (
										<TextField
											required
											fullWidth
											multiline
											rows={9}
											defaultValue={shareText}
											label='Share Text'
											onChange={(e) => setShareText(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									) : (
										<TextField
											required
											fullWidth
											multiline
											rows={9}
											value={shareText}
											onChange={(e) => setShareText(e.target.value)}
											label='Share Text'
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								</TabPanel>
								<TabPanel value={value} index={3}>
									<FormControl fullWidth sx={{ mb: 3 }}>
										<Select
											value={shareText}
											onChange={(e) => {
												setShareText(e.target.value);
												setEdit(false);
											}}>
											<MenuItem value=' '>
												<em>Select Messages</em>
											</MenuItem>
											{data
												?.filter((u) => u?.select === "FBO-Invite")
												?.map((details) => (
													<MenuItem value={details?.message}>
														{details?.message}
													</MenuItem>
												))}
										</Select>
									</FormControl>
									{!edit && (
										<EditIcon
											className='textColor borderColor'
											onClick={() => setEdit(true)}
											sx={{
												cursor: "pointer",
												borderRadius: "5px",
												zIndex: "1",
												width: "1.4em",
												height: "1.4em",
												border: "1px solid",
												position: "absolute",
												top: "12rem",
												right: "15px",
											}}
										/>
									)}
									{edit ? (
										<TextField
											required
											fullWidth
											multiline
											rows={9}
											defaultValue={shareText}
											label='Share Text'
											onChange={(e) => setShareText(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									) : (
										<TextField
											required
											fullWidth
											multiline
											rows={9}
											value={shareText}
											onChange={(e) => setShareText(e.target.value)}
											label='Share Text'
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								</TabPanel>
							</Box>
							<Button
								onClick={() => handleSendSms()}
								variant='contained'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									width: "100%",
									my: 1,
								}}>
								<SmsIcon sx={{ mr: 0.7 }} />
								Send SMS
							</Button>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default SendSms;
